import React, { useState, useEffect } from "react";
import styled from "styled-components";
import pen1 from "./../assets/images/pen1.png";
import square1 from "./../assets/images/sqaure1.png";
import link from "./../assets/images/link.png";
import trash from "./../assets/images/trash.png";
import user_plus01 from "./../assets/images/user-plus-01.png";
import user_plus02 from "./../assets/images/user-plus-02.png";
import searchIcon from "./../assets/images/search_md.png";
import clearIcon from "./../assets/images/close.png";
import { useDispatch, useSelector } from "react-redux";
import {
  addAccountData,
  addCollaboratorAdmin,
  updateAccountData,
  receiveCompaniesData,
  deleteAccount,
  getUserData,
  spoofTokens,
} from "./../action/api";
import Button from "../components/basics/button";
import Pagination from "../components/basics/pagination";
import AccountModal from "../components/modals/account";
import CollaboratorModal from "../components/modals/collaborator";
import SaveToast from "../components/toast/save";
import CancelModal from "../components/modals/cancel";
import AccountsHeader from "../components/AccountsHeader";
import { globalColor } from "../assets/variable/global";
import { deleteCookie, getErrorMessage } from "./../config/common";
import useToken from "./client/auth/useToken";
import { getRole } from "./../config/common";

export default function Accounts(props) {
  const state = useSelector((state) => state.apiReducer);
  const [currentPage, setCurrentPage] = useState(state.account_page);
  const [totalPage, setTotalPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(state.account_limit);
  const [search, setSearch] = useState("");
  const [toast, setToast] = useState(-1);
  const [errText, setErrText] = useState("");
  const [newColEmail, setNewColEmail] = useState("");
  const [addCompanyName, setAddCompanyName] = useState("");
  const [addCompanyEmail, setAddCompanyEmail] = useState("");
  const [addCompanyPassword, setAddcompanyPassword] = useState("");
  const [nameOrEmail, setNameOrEmail] = useState("");
  const [copyLink, setCopyLink] = useState("");
  const { setToken } = useToken();
  const dispatch = useDispatch();
  const [sortData, setSortData] = useState({});
  const getAccounts = (a_page, a_limit, sortData = {}) => {
    let query = "";

    if (search !== "") {
      query += '&query={"$like": {"name": "' + search + '"}}';
    } else {
      if (sortData.field === "Company") {
        query += "&orderBy=name," + (sortData.order === -1 ? "asc" : "desc");
      } else if (sortData.field === "Created") {
        query +=
          "&orderBy=created_at," + (sortData.order === -1 ? "asc" : "desc");
      }
    }
    receiveCompaniesData(
      a_page,
      a_limit,
      query
    )(dispatch).then((res) => {
      setTotalPage(Math.ceil(state.account_total / state.account_limit));
      setPageLimit(res.rowsPerPage);
      if (
        state.account_total > 0 &&
        currentPage >= Math.ceil(state.account_total / state.account_limit)
      )
        setCurrentPage(currentPage - 1);
    });
  };
  useEffect(() => {
    getAccounts(currentPage, pageLimit, sortData);
  }, [search, currentPage, sortData]);
  const [showModal, setShowModal] = useState(-2);
  const [cancelModal, setCancelModal] = useState(-2);
  const [collaboratorModalData, setCollaboratorModalData] = useState(null);
  const closeModal = () => {
    setAddCompanyName("");
    setAddCompanyEmail("");
    setAddcompanyPassword("");
    setCopyLink("");
    setShowModal(-2);
  };
  const editAccount = (index) => {
    setShowModal(index);
  };
  useEffect(() => {
    if (toast !== -1) {
      setTimeout(() => {
        setToast(-1);
      }, 3000);
    }
  }, [toast]);
  useEffect(() => {
    if (showModal >= 0) {
      setAddCompanyName(state.accounts[showModal].name);
      setAddCompanyEmail(
        state.accounts[showModal].users.length > 0
          ? state.accounts[showModal].users.find(
              (u) =>
                u.roles.find((r) => r === "OWNER") ||
                u.roles.find((r) => r === "SUPER_ADMIN")
            )?.email
          : ""
      );
    } else {
      setAddCompanyName("");
      setAddCompanyEmail("");
      setAddcompanyPassword("");
    }
  }, [showModal]);

  return (
    <AccountsWrapper className="col body p-0">
      {/* <Header
        title={"Accounts"}
        onPrevMain={() => {
          setShowModal(-1);
        }}
      /> */}
      <AccountsHeader
        title={"Accounts"}
        onAddAccount={() => {
          setErrText("");
          setCopyLink("");
          setShowModal(-1);
        }}
      />
      <BodyWrapper>
        <div className="filter-wrapper">
          <div></div>
          <div className="search-box">
            <input
              type="text"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder="Search"
            />
            <img className="search-icon" src={searchIcon} alt={"search"} />
            {search && (
              <img
                className="clear-icon"
                src={clearIcon}
                alt={"clear"}
                onClick={() => {
                  setSearch("");
                }}
              />
            )}
          </div>
        </div>
        <div className="body-card">
          {/* Table */}
          <table>
            <thead>
              <tr>
                <th scope="col" className="account-logo">
                  Logo
                </th>
                <th
                  scope="col"
                  onClick={() => {
                    if (sortData.field === "Company")
                      setSortData({ field: "Company", order: -sortData.order });
                    else setSortData({ field: "Company", order: 1 });
                  }}
                  className="account-company"
                >
                  Company{" "}
                  <i
                    className={
                      sortData.field === "Company"
                        ? sortData.order === 1
                          ? "fa fa-arrow-down"
                          : " fa fa-arrow-up"
                        : ""
                    }
                  ></i>
                </th>
                <th scope="col" className="account-website">
                  Account Owner
                </th>
                <th scope="col" className="account-website collaborator">
                  Collaborators
                </th>
                <th
                  scope="col"
                  onClick={() => {
                    if (sortData.field === "Created")
                      setSortData({ field: "Created", order: -sortData.order });
                    else setSortData({ field: "Created", order: 1 });
                  }}
                  className="account-created-at"
                >
                  Created{" "}
                  <i
                    className={
                      sortData.field === "Created"
                        ? sortData.order === 1
                          ? "fa fa-arrow-down"
                          : "fa fa-arrow-up"
                        : ""
                    }
                  ></i>
                </th>
                <th scope="col" className="account-acction"></th>
              </tr>
            </thead>
            <tbody>
              {state.accounts &&
                state.accounts.length > 0 &&
                state.accounts.map((account, index) => (
                  <React.Fragment key={index}>
                    <tr key={"account-" + index}>
                      <td className="account-logo">
                        {account.logo_url && (
                          <img src={account.logo_url} alt="logo"></img>
                        )}
                      </td>
                      <td className="account-company">
                        <h3>{account.name}</h3>
                      </td>
                      <td className="account-website">
                        <p>
                          {account.users.length > 0
                            ? account.users.find((u) =>
                                u.roles.find(
                                  (r) => r === "OWNER" || r === "SUPER_ADMIN"
                                )
                              )?.email
                            : ""}
                        </p>
                      </td>
                      <td className="account-website collaborator">
                        {account.users?.length > 1 ? (
                          <>
                            <div>
                              {
                                account.users.filter(
                                  (u) => getRole(u.roles) === "Collaborator"
                                )[0]?.email
                              }
                            </div>
                            {account.users.length > 1 &&
                              (account.users.length > 2 ? (
                                <div>+{account.users.length - 2} more</div>
                              ) : (
                                <></>
                              ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td className="account-created-at">
                        {account.created_date}
                      </td>
                      <td className="account-action">
                        <img
                          src={user_plus02}
                          alt="user-plus"
                          onClick={(e) => {
                            e.stopPropagation();
                            setNewColEmail("");
                            setErrText("");
                            setCollaboratorModalData(account);
                          }}
                        />
                        <img
                          src={square1}
                          alt="square"
                          onClick={(e) => {
                            e.stopPropagation();
                            let user = account.users?.find((u) =>
                              u.roles?.find(
                                (r) => r === "OWNER" || r === "SUPER_ADMIN"
                              )
                            );

                            spoofTokens(
                              account.id,
                              user.email
                            )(dispatch)
                              .then((res) => {
                                user.company_id = account.id;
                                setToken(res.access_token, user);
                                localStorage.setItem("user_onboard", "true");
                                window.open("/creator-dashboard/", "_blank");
                              })
                              .catch((err) => {});
                          }}
                        />
                        <img
                          src={link}
                          alt="link"
                          className={
                            account.users.find((u) =>
                              u.roles.find(
                                (r) => r === "OWNER" || r === "SUPER_ADMIN"
                              )
                            )?.is_verified
                              ? "disabled"
                              : ""
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            const user = account.users.find((u) =>
                              u.roles.find(
                                (r) => r === "OWNER" || r === "SUPER_ADMIN"
                              )
                            );
                            if (!user.is_verified) {
                              navigator.clipboard.writeText(
                                localStorage.getItem(
                                  "invite_link_" + user.email
                                )
                              );
                            }
                          }}
                        />
                        {/* <img
                          src={pen1}
                          alt="pen"
                          onClick={(e) => {
                            e.stopPropagation();
                            editAccount(index);
                          }}
                        /> */}
                        <img
                          src={trash}
                          alt="trash"
                          onClick={(e) => {
                            e.stopPropagation();
                            setErrText("");
                            setCancelModal(index);
                          }}
                        />
                      </td>
                    </tr>
                    {errText &&
                      account.id ===
                        JSON.parse(localStorage.getItem("admin_user"))
                          ?.company_id && (
                        <tr key={"account-error-" + index}>
                          <td colSpan={6} className="required-html">
                            {errText}
                          </td>
                        </tr>
                      )}
                  </React.Fragment>
                ))}
              {(!state.accounts || state.accounts.length === 0) && (
                <tr>
                  <td className="table-empty" colSpan={6}>
                    No accounts yet.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {/* Pagination */}
          {state.accounts && state.accounts.length > 0 && (
            <div className="table-pagination">
              <Pagination
                pos={currentPage}
                total={totalPage}
                rows={pageLimit}
                onPageChange={(eVal) => {
                  setCurrentPage(eVal);
                }}
              />
            </div>
          )}
        </div>
        {/* Account Modal (Add & Edit) */}
        {showModal > -2 && (
          <AccountModal
            closeModal={closeModal}
            currentAccount={showModal >= 0 ? state.accounts[showModal] : null}
            saveModal={(obj) => {
              if (showModal === -1) {
                // add
                addAccountData(obj)(dispatch)
                  .then((res) => {
                    if (res && res.status) {
                      setErrText(getErrorMessage(res.data));
                    } else {
                      setCopyLink(res.payloads[0].url);
                      localStorage.setItem(
                        "invite_link_" + obj.email,
                        res.payloads[0].url
                      );
                      getAccounts(currentPage, pageLimit);
                      setAddCompanyName("");
                      setAddCompanyEmail("");
                      setAddcompanyPassword("");
                      setToast(1);
                    }
                  })
                  .catch((err) => {});
              } else {
                const user = obj.users.find((u) =>
                  u.roles.find((r) => r === "OWNER")
                );
                let putData = {
                  company_id: obj.id,
                  company_name: obj.company_name,
                  email: obj.email,
                };
                if (obj.password !== "") {
                  putData.password = obj.password;
                  putData.password_confirm = obj.password;
                }
                // update
                updateAccountData(
                  user?.id,
                  putData
                )(dispatch)
                  .then((res) => {
                    if (res && res.status) {
                      setErrText(getErrorMessage(res.data));
                    } else {
                      getAccounts(currentPage, pageLimit);
                      setToast(1);
                      closeModal();
                    }
                  })
                  .catch((err) => {});
              }
            }}
            onCompanyNameChange={(name) => {
              setAddCompanyName(name);
              setErrText("");
              setNameOrEmail("name");
            }}
            onCompanyEmailChange={(email) => {
              setAddCompanyEmail(email);
              setErrText("");
              setNameOrEmail("email");
            }}
            onCompanyPasswordChange={(password) => {
              setAddcompanyPassword(password);
              setErrText("");
              setNameOrEmail("password");
            }}
            onCopyLink={() => {
              navigator.clipboard.writeText(copyLink);
            }}
            errText={errText}
            key={errText}
            addCompanyName={addCompanyName}
            addCompanyEmail={addCompanyEmail}
            addCompanyPassword={addCompanyPassword}
            nameOrEmail={nameOrEmail}
            copyLink={copyLink}
          />
        )}
        {showModal > -2 && (
          <div className="modal-backdrop show" onClick={closeModal}></div>
        )}
        {/* Collaborator Modal (Add & Remove) */}
        {!!collaboratorModalData && (
          <CollaboratorModal
            data={collaboratorModalData}
            closeModal={() => setCollaboratorModalData(null)}
            saveModal={() => setCollaboratorModalData(null)}
            onAddCollaborator={(email) => {
              addCollaboratorAdmin(collaboratorModalData.id, {
                company_id: collaboratorModalData.name,
                email,
              })(dispatch)
                .then((res) => {
                  if (res && !res.status) {
                    getAccounts(currentPage, pageLimit);
                    setNewColEmail("");
                    setErrText("");
                  } else {
                    if (res.data.message === "duplicated key not allowed")
                      setErrText(
                        "A collaborator with this email address already exists."
                      );
                    else setErrText("Email must be a valid email address.");
                  }
                })
                .catch((err) => console.log(err));
            }}
            onCollaboratorEdit={(e) => {
              getAccounts(currentPage, pageLimit);
            }}
            onEmailChange={(email) => {
              setErrText("");
              setNewColEmail(email);
            }}
            errText={errText}
            key={errText}
            colEmail={newColEmail}
          />
        )}
        {!!collaboratorModalData && (
          <div
            className="modal-backdrop show"
            onClick={() => setCollaboratorModalData(null)}
          ></div>
        )}
      </BodyWrapper>
      {toast !== -1 && (
        <SaveToast
          closeToast={() => {
            setToast(-1);
          }}
          toast={toast}
        />
      )}
      {cancelModal > -2 && (
        <CancelModal
          closeModal={() => setCancelModal(-2)}
          title="Delete account"
          description="Are you sure you want to delete this account?"
          saveModal={() => {
            deleteAccount(state.accounts[cancelModal].id)(dispatch)
              .then((res) => {
                if (res && res.status) {
                  setErrText("You are not able to delete your own account.");
                } else getAccounts(currentPage, pageLimit);
              })
              .catch((err) => {});
            setCancelModal(-2);
          }}
        />
      )}
      {cancelModal > -2 && (
        <div
          className="modal-backdrop show"
          onClick={() => {
            setCancelModal(-2);
          }}
        ></div>
      )}
    </AccountsWrapper>
  );
}

const AccountsWrapper = styled.div``;
const BodyWrapper = styled.div`
  flex-wrap: wrap;
  .body-card {
    display: flex;
    cursor: pointer;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    background: #fff;
    border-radius: 16px;
    margin: 20px;
    max-width: 1316px;
    min-width: 920px;
    table {
      table-layout: fixed;
      td.table-empty {
        padding: 16px 24px;
        color: rgba(71, 84, 103, 0.5);
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 0px;
      }
      border-collapse: collapse;
      border-radius: 1em;
      overflow: hidden;
      width: 100%;
      padding: 12px;
      color: var(--gray-600, #475467);
      font-family: Figtree;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      thead {
        background: #f9fafb;
        tr {
          th {
            padding: 12px 24px;
            &.account-logo {
              width: 8%;
            }
            &.account-company {
              width: 16%;
            }
            &.account-website {
              width: 22%;
            }
            &.account-created-at {
              width: 15%;
            }
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid var(--gray-200, #eaecf0);
          td {
            padding: 16px 24px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #475467;
            h3 {
              color: var(--gray-900, #101828);
              font-family: Figtree;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              margin: 0px;
            }
            p {
              color: var(--gray-600, #475467);
              font-family: Figtree;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            }
            &.required-html {
              color: red !important;
              font-size: 12px;
            }
          }
          .account-logo {
            img {
              max-width: 80px;
            }
          }
          .account-company {
            overflow-wrap: break-word;
          }
          .account-website {
            overflow-wrap: break-word;
            p {
              margin: 0px;
            }
          }
          .account-action {
            img {
              width: 40px;
              height: 40px;
              &.disabled {
                pointer-events: none; /* Prevent interaction */
                opacity: 0.5; /* Dim the image */
                cursor: not-allowed; /* Show a "not allowed" cursor */
              }
            }
          }
        }
      }
      margin-bottom: 0px;
    }
  }
  .filter-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 18px 20px;
    color: var(--gray-700, #344054);
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    max-width: 1316px;
    min-width: 524px;
    .search-box {
      position: relative;
      display: flex;
      align-items: center;
      input {
        padding-left: 37px !important;
      }
      .search-icon {
        position: absolute;
        left: 10px; /* Adjust to fit the input */
        width: 20px; /* Adjust based on icon size */
        height: 20px; /* Adjust based on icon size */
      }
      .clear-icon {
        position: absolute;
        right: 5px; /* Adjust to fit the input */
        width: 30px; /* Adjust based on icon size */
        height: 30px; /* Adjust based on icon size */
        cursor: pointer;
      }
    }
    input {
      border-radius: 8px;
      padding: 10px 14px;
      border: 1px solid var(--gray-300, #d0d5dd);
      background: var(--base-white, #fff);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  }
  .table-pagination {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 24px;
  }
`;
