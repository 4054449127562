import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import {
  globalColor,
  text_lg,
  text_md_semibold,
  text_sm_regular,
} from "./../../../assets/variable/global";
import EditHeader from "../../../components/ediHeader";
import Button from "../../../components/basics/button";
import NotFoundErrorModal from "../../../components/modals/notFoundError";
import ServerErrorModal from "../../../components/modals/serverError";
import bugIcon from "./../../../assets/images/bug_icon.png";
import gift from "./../../../assets/images/gift-01.png";
import Warning from "./../../../assets/images/warning-incentive.png";
import Subtract from "./../../../assets/images/Subtract.png";
import trash from "./../../../assets/images/trash-02.png";
import coupon from "./../../../assets/images/coupon.png";
import sadCoupon from "./../../../assets/images/sad_coupon.png";
import ArrowDown from "./../../../assets/images/arrow-down.png";
import arrowLeft from "./../..//../assets/images/arrow-left.png";
import radio_check from "./../../../assets/images/radio_check.svg";
import panda from "./../../../assets/images/interview_goal_panda.png";
import pandaLogo from "./../../../assets/images/goal_communication_panda.png";
import ArrowUp from "./../../../assets/images/arrow-up.png";
import amazon from "./../../../assets/images/amazon.png";
import Doctors from "./../../../assets/images/Doctors.png";
import sendYellow from "./../../../assets/images/sendYellow.png";
import CustomSelect from "../../../components/basics/CustomOption";
import SaveToast from "../../../components/toast/save";
import { useNavigate } from "react-router-dom";
import {
  getSlate,
  deleteSlate,
  getInterview,
  saveInterview,
  updateSlate,
  addQuestion,
  updateQuestion,
  deleteQuestion,
  getSlateUrlCode,
  updateSlateActivate,
  createOnboardProspectSession,
  getOnboardTranscript,
  postOnboardTranscript,
  updateOnboardTranscript,
  updateCompletedOnboardTranscript,
  finishTranscript,
  deleteAnswer,
  postInterviewIncentive,
  putInterviewIncentive,
  getInterviewIncentive,
  deleteInterviewIncentive,
} from "../../../action/api";
import { useDispatch } from "react-redux";
import TypingComponent from "../../../pages/emerald/typing";
import { handleBeforeUnload, getCookie } from "../../../config/common";
import SaveCancelModal from "../../../components/modals/save";
import ActivateModal from "../../../components/modals/activate";

export default function EditSlate(props) {
  const { new_slate } = props;
  const [slate, setSlate] = useState(props.slate);
  const [activatedStatus, setActivatedStatus] = useState(
    slate.status === "notActive" ? false : true
  );
  const [createIncentiveFlag, setCreateIncentiveFlag] = useState(false);
  const [unSaved, setUnsaved] = useState(false);
  const [step, setStep] = useState(0);
  const [text, setText] = useState("");
  const [curFlag, setCurFlag] = useState(false);
  const [curArr, setCurArr] = useState("");
  const [doneFlag, setDoneFlag] = useState(false);
  const [typing, setTyping] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(0);
  const [context, setContext] = useState("");
  const [originalArr, setOriginalArr] = useState([]);
  const [activateModal, setActivateModal] = useState(-1);
  const dispatch = useDispatch();
  const [headline, setHeadline] = useState("Please tell us what you think");
  const [incentive, setIncentive] = useState(null);
  const [getIncentive, setGetIncentive] = useState("none");
  const [incentiveType, setIncentiveType] = useState("giftCard");
  const [currentSlate, setCurrentSlate] = useState(slate);
  const [limitRadio, setLimitRadio] = useState("limit");
  const [maxRespondent, setMaxRespondent] = useState(250);
  const [goalStatus, setGoalStatus] = useState("beforeSet");
  const [toast, setToast] = useState(-1);
  const [incentiveId, setIncentiveId] = useState(null);
  const [delToast, setDelToast] = useState(-1);
  const [unCompletedCounts, setUnCompletedCounts] = useState(7);
  const [completedFlag, setCompletedFlag] = useState(-1);
  const [saved, setSaved] = useState(false);
  const [saveCancelFlag, setSaveCancelFlag] = useState(-1);
  const [interview, setInterview] = useState({});
  const [despIncentive, setDespIncentive] = useState("");
  const [despOrgIncentive, setDespOrgIncentive] = useState("");
  const [inputFlag, setInputFlag] = useState(-1);
  const [transcript_id, setTranscriptID] = useState(-1);
  let navigate = useNavigate();
  useEffect(() => {
    if (!saved) window.addEventListener("beforeunload", handleBeforeUnload);
    else window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [saved]);
  useEffect(() => {
    if (toast === 1) {
      setTimeout(() => {
        setToast(-1);
      }, 3000);
    }
  }, [toast]);
  useEffect(() => {
    if (doneFlag) checkScroll();
  }, [doneFlag]);
  useEffect(() => {
    if (delToast) {
      setTimeout(() => {
        setDelToast(-1);
      }, 3000);
    }
  }, [delToast]);
  const [apiStatus, setApiStatus] = React.useState(200);
  const [updateQuestionError, setUpdateQuestionError] = useState(false);
  useEffect(() => {
    if (new_slate) {
      localStorage.setItem("new_slate", slate.id);
    }
    setTyping(true);
    setCurrentOrder(0);
    getSlate(slate.id)(dispatch)
      .then((res) => {
        setSlate(res.payloads[0]);
        if (res.payloads[0].onboarding_interview_is_complete) {
          getOnboardTranscript(slate.onboarding_interview_id)(dispatch)
            .then((res) => {
              const { payloads } = res;
              if (
                payloads[0] &&
                payloads[0].transcript &&
                payloads[0].transcript.length > 0 &&
                payloads[0].transcript[0].answer_text !== ""
              ) {
                setTranscriptID(payloads[0].id);
                setMainArr([...payloads[0].transcript]);
                setOriginalArr(
                  JSON.parse(JSON.stringify(payloads[0].transcript))
                );
                if (payloads[0].is_complete) setGoalStatus("AfterSet");
                else {
                  setGoalStatus("Setting");
                  let tempPos = 0;
                  const tempPosArr = [];
                  for (let i = 0; i < payloads[0].transcript.length; i++) {
                    tempPosArr.push(payloads[0].transcript[i]);
                    tempPos = payloads[0].transcript[i].order;
                    if (
                      !payloads[0].transcript[i].answer_text ||
                      payloads[0].transcript[i].answer_text === ""
                    )
                      break;
                  }
                  if (
                    payloads[0].transcript[payloads[0].transcript.length - 1]
                      .answer_text &&
                    tempPos >=
                      payloads[0].transcript[payloads[0].transcript.length - 1]
                        .order
                  )
                    setDoneFlag(true);
                  setCurrentOrder(tempPos);
                  setMainArr([...tempPosArr]);
                  setTyping(true);
                  checkScroll();
                }
              }
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => {});
    getInterview(
      slate.id,
      slate.interview_ids === null ? undefined : slate.interview_ids[0]
    )(dispatch)
      .then((res) => {
        if (res && res.status) {
          setApiStatus(res.status);
        } else {
          const { payloads } = res;
          if (payloads.length > 0) {
            let tempCount = 7;
            if (payloads[0].headline_complete) tempCount--;
            if (payloads[0].goals_complete) tempCount--;
            if (payloads[0].exit_message_complete) tempCount--;
            if (payloads[0].headline_complete) tempCount--;
            if (payloads[0].questions_complete) tempCount--;
            if (payloads[0].incentive_complete) tempCount--;
            if (payloads[0].respondents_complete) tempCount--;
            setUnCompletedCounts(tempCount);
            setCompletedFlag(-1);
            setCurrentSlate(payloads[0]);
            setHeadline(payloads[0].headline || headline);
            setPandaBehavior(payloads[0].formal);
            setExitMessage(payloads[0].exit_message || exitMessage);
            setMaxRespondent(payloads[0].max_respondents);
            if (payloads[0].incentive_id && payloads[0].incentive_id !== "") {
              setIncentiveId(payloads[0].incentive_id);
              getInterviewIncentive(
                slate.interview_ids[0],
                payloads[0].incentive_id
              )(dispatch)
                .then((res) => {
                  const { payloads } = res;
                  setGetIncentive(payloads[0].audience);
                  setDespIncentive(payloads[0].description);
                  setDespOrgIncentive(payloads[0].description);
                })
                .catch((err) => {
                  setIncentiveId(null);
                  setDespIncentive("");
                  setDespOrgIncentive("");
                });
            }
            setLimitRadio(
              payloads[0].max_respondents === -1 ? "unlimit" : "limit"
            );
            if (payloads[0].questions !== null) {
              payloads[0].questions.map((question) => {
                question.add_or_update = "update";
              });
              setQuestions(
                payloads[0].questions.sort((a, b) => a.order - b.order)
              );
            } else setQuestions([]);
            setStepArr([
              {
                no: 0,
                completed: payloads[0].headline_complete
                  ? payloads[0].headline_complete
                  : headline.length > 3,
                disabled: false,
                title: "Headline",
              },
              {
                no: 1,
                completed: true,
                disabled: false,
                title: "Interview goals",
              },
              {
                no: 2,
                completed: true,
                disabled: false,
                title: "Panda Behavior",
              },
              {
                no: 3,
                completed: payloads[0].questions_complete
                  ? payloads[0].questions_complete
                  : questions.length > 0,
                disabled: false,
                title: "Panda Questions",
              },
              {
                no: 4,
                completed: true,
                disabled: false,
                title: "Incentive",
              },

              {
                no: 5,
                completed: payloads[0].exit_message_complete
                  ? payloads[0].exit_message_complete
                  : exitMessage.length > 3,
                disabled: false,
                title: "Exit message",
              },
              {
                no: 6,
                completed: true,
                disabled: true,
                title: "Respondents",
              },
            ]);
            setInterview(payloads[0]);
          }
        }
      })
      .catch((err) => {});
  }, []);
  const vendorOptions = [
    {
      value: "amazon",
      label: "Amazon",
      icon: amazon,
    },
  ];
  const [mainArr, setMainArr] = useState([]);
  const donationOptions = [
    {
      value: "Doctors Without Borders",
      label: "Doctors Without Borders",
      icon: Doctors,
    },
  ];
  const [questions, setQuestions] = useState([]);
  const [exitMessage, setExitMessage] = useState("Thank you!");
  const [stepArr, setStepArr] = useState([
    { no: 0, completed: false, disabled: false, title: "Headline" },
    { no: 1, completed: false, disabled: false, title: "Interview goals" },
    { no: 2, completed: false, disabled: false, title: "Panda Behavior" },
    { no: 3, completed: false, disabled: false, title: "Panda Questions" },
    { no: 4, completed: false, disabled: false, title: "Incentive" },
    { no: 5, completed: false, disabled: false, title: "Exit message" },
    { no: 6, completed: false, disabled: false, title: "Respondents" },
  ]);
  const pandaBehaviorList = {
    formal: "Formal",
    professional: "Professional",
    casualApproachable: "Casual and Approachable",
    humorous: "Humorous",
  };
  const [pandaBehavior, setPandaBehavior] = useState("");
  const headlineRef = useRef(null);
  const incentiveRef = useRef(null);
  const goalsRef = useRef(null);
  const pandaBehaviorRef = useRef(null);
  const exitMessageRef = useRef(null);
  const respondentsRef = useRef(null);
  const questionsRef = useRef(null);
  const containerRef = useRef(null);
  const chatBodyRef = useRef(null);
  const checkScroll = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.style.scrollBehavior = "smooth";
      chatBodyRef.current.style.transition = "scroll 100ms ease-in";
      chatBodyRef.current.scrollIntoView({ block: "end" });
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };
  const textareaRef = useRef(null);
  useEffect(() => {
    if (typing && text !== "") {
      checkScroll();
    }
  }, [typing]);
  function checkQuestionEmpty(arr, index) {
    if (
      arr[index].is_follow_up &&
      arr[index].question_text === "" &&
      arr[index].context !== ""
    )
      return 1;
    if (
      arr[index].is_follow_up &&
      arr[index].question_text !== "" &&
      arr[index].context !== ""
    )
      return 2;
    return -1;
  }
  function checkContextQuizArr(arr, flag, curText) {
    let tempLength = arr.length;
    if (tempLength === 0) return -2;
    const tempContext = arr[tempLength - 1].context
      ? arr[tempLength - 1].context
      : "";
    const tempQuestion = arr[tempLength - 1].question_text
      ? arr[tempLength - 1].question_text
      : "";
    let tempText =
      tempContext === "" ? tempQuestion : tempContext + " " + tempQuestion;
    let isFollowUp = arr[tempLength - 1].is_follow_up;
    if (tempLength > 0 && !flag && curText !== tempText) {
      if (tempQuestion === "" && tempContext !== "" && isFollowUp) return 0;
      else if (tempQuestion !== "" && tempContext !== "" && isFollowUp)
        return 1;
      else return 2;
    } else if (flag) return -1;
    return -2;
  }
  function getQuiz(a, b) {
    if (a.includes(b)) return a;
    return b + " " + a;
  }
  useEffect(() => {
    checkScroll();
    let condition = checkContextQuizArr(mainArr, doneFlag, curArr);
    if (condition === 0) {
      setCurArr(mainArr[mainArr.length - 1].context);
      setCurFlag(true);
      setTyping(true);
    } else if (condition === 1) {
      setCurArr(
        getQuiz(
          mainArr[mainArr.length - 1].question_text,
          mainArr[mainArr.length - 1].context
        )
      );
    } else if (condition === 2) {
      setCurArr(
        mainArr[mainArr.length - 1].question_text || `Q` + mainArr.length
      );
    } else {
    }
    if (condition > -2) {
      setCurFlag(true);
      setTyping(true);
      checkScroll();
    }
  }, [mainArr]);
  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const { scrollTop, clientHeight } = containerRef.current;
        const elements = containerRef.current.children;

        for (const element of elements) {
          const { offsetTop, offsetHeight } = element;
          if (
            scrollTop + clientHeight >= offsetTop - 115 &&
            scrollTop < offsetTop - 115 + offsetHeight
          ) {
            if (element === headlineRef.current) setStep(0);
            else if (element === goalsRef.current) setStep(1);
            else if (element === pandaBehaviorRef.current) setStep(2);
            else if (element === questionsRef.current) setStep(3);
            else if (element === incentiveRef.current) setStep(4);
            else if (element === exitMessageRef.current) setStep(5);
            else if (element === respondentsRef.current) setStep(6);
            break;
          }
        }
      }
    };

    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (headline.length >= 4 && inputFlag === 1) setInputFlag(0);
    if (exitMessage.length >= 4 && inputFlag === 1) setInputFlag(0);
  }, [headline, exitMessage]);
  const handleStepClick = (stepIndex) => {
    setStep(stepIndex);

    switch (stepIndex) {
      case 0:
        headlineRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        break;
      case 1:
        goalsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case 2:
        pandaBehaviorRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        break;
      case 3:
        questionsRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        break;
      case 4:
        incentiveRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        break;
      case 5:
        exitMessageRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        break;
      case 6:
        respondentsRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        break;
      default:
        break;
    }
  };
  const updateQuestionOrder = (order, questionIndex) => {
    const tempQuestionsArr = [...questions];
    const tempQuestion = questions[questionIndex];
    tempQuestionsArr[questionIndex].order += order;
    tempQuestionsArr[questionIndex + order].order -= order;
    tempQuestionsArr[questionIndex] = tempQuestionsArr[questionIndex + order];
    tempQuestionsArr[questionIndex + order] = tempQuestion;
    setQuestions([...tempQuestionsArr]);
  };
  const handleLimitRadio = (event) => {
    setMaxRespondent(0);
    setLimitRadio(event.target.value);
  };
  const handleGetIncentive = (selectedValue) => {
    setGetIncentive(selectedValue);
  };
  const handleIncentiveType = (event) => {
    setIncentiveType(event.target.value);
  };
  //  save draft
  const submitFunc = (type = 0) => {
    //  update slate name
    updateSlate(slate.id, {
      name: document.getElementById("slate_name").innerHTML,
    })(dispatch)
      .then((res) => {})
      .catch((err) => {});
    // save interview
    saveInterview(slate.id, slate.interview_ids[0] || undefined, {
      behavior_complete: true,
      exit_message: exitMessage,
      exit_message_complete: exitMessage === "" ? false : true,
      formal: pandaBehavior,
      goals_complete: true,
      headline: headline,
      headline_complete: headline === "" ? false : true,
      incentive_complete: true,
      incentive_id: incentiveId,
      max_respondents: limitRadio === "unlimit" ? -1 : Number(maxRespondent),
      questions_complete: questions.length > 0 ? true : false,
      respondenets_complete: true,
      target_id: null,
    })(dispatch)
      .then((res) => {
        if (res && res.status) {
          setApiStatus(res.status);
        } else {
          setInputFlag(1);
          if (res && res.code) {
            const error_arr = JSON.parse(res.message);
            if (error_arr[0].field === "headline")
              alert(
                "Headline is required and must be at least four characters in length"
              );
            else if (error_arr[0].field === "exit_message")
              alert(
                "Exit Message is required and must be at least four characters in length"
              );
            return;
          } else if (res && res.payloads) {
            setToast(1);
            const { payloads } = res;
            let tempCount = 7;
            if (payloads[0].headline_complete) tempCount--;
            if (payloads[0].goals_complete) tempCount--;
            if (payloads[0].exit_message_complete) tempCount--;
            if (payloads[0].headline_complete) tempCount--;
            if (payloads[0].questions_complete) tempCount--;
            if (payloads[0].incentive_complete) tempCount--;
            if (payloads[0].respondents_complete) tempCount--;
            setUnCompletedCounts(tempCount);
            setCompletedFlag(-1);
            setStepArr([
              {
                no: 0,
                completed: payloads[0].headline_complete,
                disabled: false,
                title: "Headline",
              },
              {
                no: 1,
                completed: payloads[0].goals_complete,
                disabled: false,
                title: "Interview goals",
              },
              {
                no: 2,
                completed: payloads[0].behavior_complete,
                disabled: false,
                title: "Panda Behavior",
              },
              {
                no: 3,
                completed: payloads[0].questions_complete,
                disabled: false,
                title: "Panda Questions",
              },
              {
                no: 4,
                completed: payloads[0].incentive_complete,
                disabled: false,
                title: "Incentive",
              },

              {
                no: 5,
                completed: res.payloads[0].exit_message_complete,
                disabled: false,
                title: "Exit message",
              },
              {
                no: 6,
                completed: payloads[0].respondents_complete,
                disabled: true,
                title: "Respondents",
              },
            ]);
            setUnsaved(false);
            if (type === 1) {
              setToast(1);
              setSaved(true);
              navigate("/creator-dashboard/smart-interviews");
            }
            let submitReady = true;
            questions.map((question) => {
              if (question.text === "" || question.text.length < 4) {
                setUpdateQuestionError(true);
                submitReady = false;
              }
            });
            if (submitReady) {
              let tempQuestions = [...questions];
              questions.map((question, idx) => {
                if (question.add_or_update === "add")
                  addQuestion(
                    slate.interview_ids[0],
                    question
                  )(dispatch).then((res) => {
                    if (res && res.status) {
                      setApiStatus(res.status);
                      setUpdateQuestionError(true);
                    } else {
                      const { payloads } = res;
                      payloads[0].add_or_update = "update";
                      tempQuestions[idx] = payloads[0];
                      setQuestions(tempQuestions);
                    }
                  });
                else {
                  updateQuestion(
                    question.interview_id,
                    question.id,
                    question
                  )(dispatch)
                    .then((res) => {
                      if (res && res.status) {
                        setApiStatus(res.status);
                        setUpdateQuestionError(true);
                      }
                    })
                    .catch((err) => {
                      setToast(-1);
                      setSaved(false);
                      console.log("Update Question Err" + err);
                    });
                }
              });
              setToast(1);
              setSaved(true);
              setInterview(payloads[0]);
            } else setToast(-1);
          } else {
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateOnboardingTranscript = (tempQuestions) => {
    let questions = [];
    tempQuestions.map((que, idx) => {
      if (
        que.answer_text &&
        originalArr[idx] &&
        que.answer_text !== originalArr[idx].answer_text
      )
        questions.push({
          question_id: que.question_id,
          questioned_at: que.questioned_at,
          answer_id: que.answer_id,
          answered_at: que.answered_at,
          answer_text: que.answer_text,
        });
    });
    const obj = {
      is_complete: true,
      transcript_id: transcript_id,
      transcript: questions,
    };

    updateCompletedOnboardTranscript(
      slate.onboarding_interview_id,
      transcript_id,
      obj
    )(dispatch)
      .then((res) => {
        setOriginalArr(JSON.parse(JSON.stringify(res.payloads[0].transcript)));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const finishTranscriptFunc = () => {
    const tempUser = JSON.parse(localStorage.getItem("user"));

    finishTranscript(
      slate.onboarding_interview_id,
      transcript_id,
      tempUser.email
    )(dispatch)
      .then((res) => {
        setMainArr([...res.payloads[0].transcript]);
        setOriginalArr(JSON.parse(JSON.stringify(res.payloads[0].transcript)));
      })
      .catch((err) => {});
  };
  const getActivate = (id = 0) => {
    getSlateUrlCode(slate.id)(dispatch)
      .then((res) => {
        if (res && res.status) {
          setApiStatus(res.status);
        } else {
          const { payloads } = res;

          setActivatedStatus(true);
          setCurrentSlate({ ...payloads[0] });
          if (id === 0) setActivateModal(1);
          else
            navigate("/smartinterviews/" + payloads[0].code, {
              state: { slate: payloads[0], test: true, interview: interview },
            });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleActivate = (id = 0) => {
    updateSlateActivate(slate.id)(dispatch)
      .then((res) => {
        if (res && res.status) {
          setApiStatus(res.status);
        } else {
          const { payloads } = res;

          setActivatedStatus(true);
          setCurrentSlate({ ...payloads[0] });
          if (id === 0) setActivateModal(1);
          else
            navigate("/smartinterviews/" + payloads[0].code, {
              state: { slate: payloads[0], test: true, interview: interview },
            });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  function formatDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const updateData = (obj) => {
    const tempA = [...mainArr];

    if (!doneFlag) tempA[tempA.length - 1].answer_text = text;
    else
      tempA[tempA.length - 1].answer_text =
        tempA[tempA.length - 1].answer_text === undefined
          ? text
          : tempA[tempA.length - 1].answer_text + " " + text;
    setText("");
    setCurFlag(false);
    setMainArr([...tempA]);
    checkScroll();

    updateOnboardTranscript(
      slate.onboarding_interview_id,
      transcript_id,
      obj
    )(dispatch)
      .then((res) => {
        if (res && res.status) {
          setApiStatus(res.status);
        } else {
          const tempMainArr = [...mainArr];
          const { payloads } = res;
          let leng = payloads[0].transcript.findIndex(
            (item) => currentOrder < item.order && item.order < currentOrder + 1
          );
          leng =
            leng === -1
              ? payloads[0].transcript.findIndex(
                  (item) => item.order === currentOrder
                )
              : leng;
          if (
            currentOrder !== payloads[0].transcript[leng].order &&
            checkQuestionEmpty(payloads[0].transcript, leng) === 1
          ) {
            const tempQuestionText =
              payloads[0].transcript[leng].context +
              "? " +
              payloads[0].transcript[leng].question_text;
            const obj1 = {
              transcript: [
                {
                  question_id: payloads[0].transcript[leng].question_id,
                  question_text: tempQuestionText,
                  questioned_at: formatDate(),
                  answer_id: payloads[0].transcript[leng].answer_id,
                  answered_at: formatDate(),
                  answer_text: "",
                  is_follow_up: payloads[0].transcript[leng].is_follow_up,
                  order: payloads[0].transcript[leng].order,
                  context: payloads[0].transcript[leng].context,
                },
              ],
            };
            updateOnboardTranscript(
              slate.onboarding_interview_id,
              transcript_id,
              {
                is_complete: true,
                transcript: obj1.transcript,
                transcript_id: transcript_id,
              }
            )(dispatch).then((res1) => {
              setCurrentOrder(payloads[0].transcript[leng].order);
              setContext(obj1.transcript[0].question_text);
              setMainArr([...tempMainArr, res1.payloads[0].transcript[leng]]);
            });
          } else if (
            payloads[0].transcript[leng].question_text !== "" &&
            currentOrder !== payloads[0].transcript[leng].order
          ) {
            setCurrentOrder(payloads[0].transcript[leng].order);
            if (payloads[0].transcript[leng].context !== "") {
              setContext(payloads[0].transcript[leng].context);
              setMainArr([...tempMainArr, payloads[0].transcript[leng]]);
            } else {
              setMainArr([...tempMainArr, payloads[0].transcript[leng]]);
            }
          } else if (
            payloads[0].transcript.length >= 2 &&
            checkQuestionEmpty(payloads[0].transcript, leng) === 1 &&
            currentOrder !== payloads[0].transcript[leng].order
          ) {
            setCurrentOrder(payloads[0].transcript[leng].order);
            setContext(payloads[0].transcript[leng].context);
            setMainArr([...tempMainArr, payloads[0].transcript[leng]]);
          } else if (
            payloads[0].transcript.length >= 2 &&
            checkQuestionEmpty(payloads[0].transcript, leng) === 2 &&
            currentOrder !== payloads[0].transcript[leng].order
          ) {
            setCurrentOrder(payloads[0].transcript[leng].order);
            setContext(
              payloads[0].transcript[leng].context +
                " " +
                payloads[0].transcript[leng].question_text
            );
            setMainArr([...tempMainArr, payloads[0].transcript[leng]]);
          } else {
            const tempOrder =
              Math.floor(payloads[0].transcript[leng].order) + 1;

            if (tempOrder <= originalArr[originalArr.length - 1].order) {
              setCurrentOrder(tempOrder);
              tempMainArr.push(originalArr[tempOrder]);
              setMainArr([...tempMainArr]);
            } else {
              setDoneFlag(true);
              setTyping(true);
            }
          }
        }
      })
      .catch((err) => console.log("GetOnboardTranscript Err", err));
  };
  const sendFunc = () => {
    if (text !== "" && !doneFlag) {
      const tempQT =
        mainArr[mainArr.length - 1].context !== "" &&
        mainArr[mainArr.length - 1].context &&
        mainArr[mainArr.length - 1].is_follow_up
          ? mainArr[mainArr.length - 1].context
          : "";

      const obj1 = {
        transcript: [
          {
            question_text:
              tempQT + " " + mainArr[mainArr.length - 1].question_text,
            questioned_at:
              mainArr[mainArr.length - 1].questioned_at || formatDate(),
            answered_at: formatDate(),
            answer_text: text,
            is_follow_up: mainArr[mainArr.length - 1].is_follow_up,
            order: mainArr[mainArr.length - 1].order,
          },
        ],
      };
      obj1.transcript[0].question_id = mainArr[mainArr.length - 1].question_id;
      obj1.is_complete = true;
      obj1.transcript_id = transcript_id;
      if (getCookie("onboard_" + slate.onboarding_interview_id) === null) {
        createOnboardProspectSession(slate.id)(dispatch)
          .then((res) => {
            updateData(obj1);
          })
          .catch((err) => console.log(err));
      } else updateData(obj1);
    } else if (doneFlag) {
      setTyping(false);
      const tempObj = { ...mainArr[mainArr.length - 1] };
      tempObj.answer_text =
        tempObj.answer_text === undefined
          ? text
          : tempObj.answer_text + " " + text;
      tempObj.question_id = mainArr[mainArr.length - 1].question_id;
      if (tempObj.answer_id === "") delete tempObj.answer_id;
      if (tempObj.answer_id === "") delete tempObj.answer_id;
      if (tempObj.answered_at === "") tempObj.answered_at = formatDate();
      if (tempObj.questioned_at === "") tempObj.questioned_at = formatDate();
      const obj1 = {
        transcript: [tempObj],
      };
      obj1.is_complete = true;
      obj1.transcript_id = transcript_id;
      if (getCookie("onboard_" + slate.onboarding_interview_id) === null) {
        createOnboardProspectSession(slate.id)(dispatch)
          .then((res) => {
            updateData(obj1);
          })
          .catch((err) => console.log(err));
      } else updateData(obj1);
      checkScroll();
    }
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter" && text !== "") {
        event.preventDefault();
        sendFunc();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  });
  function getQuestion(a, b) {
    if (a.includes(b)) return a;

    return b + " " + a;
  }
  const updateIncentive = () => {
    setCreateIncentiveFlag(true);
    if (
      despIncentive.length < 4 ||
      (getIncentive === "all" && despIncentive === despOrgIncentive)
    ) {
      return;
    }
    if (getIncentive === "all") {
      putInterviewIncentive(slate.interview_ids[0], incentiveId, {
        audience: getIncentive,
        description: despIncentive,
      })(dispatch)
        .then((res) => {
          setCreateIncentiveFlag(false);
          setDespOrgIncentive(res.payloads[0].description);
          setIncentive(null);
        })
        .catch((err) => console.log("Err-putInterviewIncentive", err));
    } else {
      deleteInterviewIncentive(
        slate.interview_ids[0],
        incentiveId
      )(dispatch)
        .then((res) => {
          setCreateIncentiveFlag(false);
          setIncentiveId(null);
          setDespOrgIncentive("");
          setDespIncentive("");
          setIncentive(null);
        })
        .catch((err) => console.log("err-delete", err));
    }
  };
  const createIncentive = () => {
    setCreateIncentiveFlag(true);
    if (despIncentive.length < 4 || getIncentive === "none") {
      return;
    }
    postInterviewIncentive(slate.interview_ids[0], {
      audience: getIncentive,
      description: despIncentive,
    })(dispatch)
      .then((res) => {
        const { payloads } = res;

        setCreateIncentiveFlag(false);
        setIncentiveId(payloads[0].id);
        setDespOrgIncentive(payloads[0].description);
        setIncentive(null);
      })
      .catch((err) => console.log("Err-create-incentive", err));
  };

  return (
    <DashboardWrapper>
      <EditHeader
        title={"Smart Interview Slates"}
        subTitle={slate.name}
        editBtn={"Test Drive"}
        disable={unCompletedCounts > 0 || !activatedStatus ? true : false}
        onPrevMain={(e) => {
          if (e === 100) {
            if (unSaved) setSaveCancelFlag(1);
            else if (!unSaved) navigate("/creator-dashboard/smart-interviews");
          } else if (unCompletedCounts > 0 || !activatedStatus) {
          } else {
            if (e === 2) {
              if (unCompletedCounts !== 0) {
                setCompletedFlag(1);
              } else getActivate(-1);
            } else if (unSaved) setSaveCancelFlag(1);
            else if (!unSaved) navigate("/creator-dashboard/smart-interviews");
          }
        }}
        slateId={slate.id}
        saved={saved}
      />
      <div className="scroll-container">
        <InterviewSetup>
          <StepBar>
            <h4 style={text_lg}>Smart Interview Setup</h4>
            {stepArr.map((subStep, index) => (
              <div
                className={`stepBtn ${subStep.no === step ? "selected" : ""} ${index === stepArr.length - 1 && "last-btn"}`}
                key={"step-" + index}
                onClick={() => handleStepClick(subStep.no)}
              >
                {!subStep.completed && <div className="circle"></div>}
                <h3
                  style={{
                    ...text_md_semibold,
                    color:
                      subStep.no === step
                        ? globalColor.primary_700
                        : globalColor.gray_700,
                  }}
                >
                  {subStep.title}
                </h3>
              </div>
            ))}
            {completedFlag === 1 && (
              <div className="uncomplete-err">
                <img src={bugIcon} alt="bug" />
                <h4>
                  {unCompletedCounts} items need to be completed to activate
                  this interview.
                </h4>
              </div>
            )}
          </StepBar>
          <MainSetup ref={containerRef}>
            {/* Headline */}
            <div className="card" ref={headlineRef}>
              <h3 style={{ ...text_lg, fontWeight: 700 }}>Headline</h3>
              <p style={text_sm_regular}>
                Messaging on the first screen, encouraging the respondent to
                participate in the interview
              </p>
              <input
                type="text"
                className={`form-control ${inputFlag === 1 && headline.length < 4 && "form-validation"}`}
                value={headline}
                onChange={(e) => {
                  setHeadline(e.target.value);
                  setUnsaved(true);
                }}
                placeholder={"Please tell us what you think"}
              />
              {inputFlag === 1 && headline.length < 4 && (
                <p className="required-html">
                  Headline is required and must be at least four characters in
                  length.
                </p>
              )}
            </div>
            {/* Interview Goals */}
            <div className="card" ref={goalsRef}>
              <div className="card-header" ref={goalsRef}>
                <h3 style={{ ...text_lg, fontWeight: 700 }}>Interview Goals</h3>
                {goalStatus === "beforeSet" && (
                  <Button
                    title={"Set Goals"}
                    icon="pen"
                    outline
                    onClickBtn={() => {
                      createOnboardProspectSession(slate.id)(dispatch)
                        .then((res) => {
                          postOnboardTranscript(
                            slate.onboarding_interview_id || undefined
                          )(dispatch)
                            .then((res) => {
                              const { payloads } = res;
                              setTranscriptID(payloads[0].id);
                              setOriginalArr(
                                JSON.parse(
                                  JSON.stringify(payloads[0].transcript)
                                )
                              );
                              setGoalStatus("Setting");

                              let tempPos = 0;
                              const tempPosArr = [];
                              for (
                                let i = 0;
                                i < payloads[0].transcript.length;
                                i++
                              ) {
                                tempPosArr.push(payloads[0].transcript[i]);
                                tempPos = payloads[0].transcript[i].order;
                                if (
                                  !payloads[0].transcript[i].answer_text ||
                                  payloads[0].transcript[i].answer_text === ""
                                )
                                  break;
                              }
                              if (
                                payloads[0].transcript[
                                  payloads[0].transcript.length - 1
                                ].answer_text &&
                                tempPos >=
                                  payloads[0].transcript[
                                    payloads[0].transcript.length - 1
                                  ].order
                              )
                                setDoneFlag(true);
                              setCurrentOrder(tempPos);
                              setMainArr([...tempPosArr]);
                              setTyping(true);
                              checkScroll();
                            })
                            .catch((err) =>
                              console.log("PostOnboardTranscript Err", err)
                            );
                        })
                        .catch((err) => console.log(err));
                    }}
                  />
                )}
                {goalStatus === "Setting" && (
                  <Button
                    title={"Done"}
                    icon="checkIcon"
                    onClickBtn={() => {
                      if (
                        getCookie(
                          "onboard_" + slate.onboarding_interview_id
                        ) === null
                      ) {
                        createOnboardProspectSession(slate.id)(dispatch)
                          .then((res) => {
                            finishTranscriptFunc();
                          })
                          .catch((err) => console.log(err));
                      } else finishTranscriptFunc();
                      setGoalStatus("AfterSet");
                    }}
                  />
                )}
                {goalStatus === "AfterSet" && (
                  <Button
                    title={"Change Goals"}
                    icon="pen"
                    outline
                    onClickBtn={() => {
                      setGoalStatus("ChangeSet");
                    }}
                  />
                )}
                {goalStatus === "ChangeSet" && (
                  <Button
                    title={"Done"}
                    icon="checkIcon"
                    onClickBtn={() => {
                      if (
                        !getCookie("onboard_" + slate.onboarding_interview_id)
                      ) {
                        createOnboardProspectSession(slate.id)(dispatch)
                          .then((res) => {
                            const tempMainArr = [...mainArr];

                            updateOnboardingTranscript(tempMainArr);
                          })
                          .catch((err) => {});
                      } else {
                        const tempMainArr = [...mainArr];

                        updateOnboardingTranscript(tempMainArr);
                      }
                      setGoalStatus("AfterSet");
                    }}
                  />
                )}
              </div>
              <p style={text_sm_regular}>
                What do you want to learn from the Smart Interview respondents?
              </p>
              {goalStatus === "beforeSet" && (
                <div className="goal-result">
                  <img src={panda} alt="panda" />
                  <div style={{ ...text_md_semibold, fontWeight: 800 }}>
                    What do you want to learn from this slate of Smart
                    Interviews? Click the Set Goals button and tell Panda what
                    you want!
                  </div>
                </div>
              )}
              {goalStatus === "Setting" && (
                <div className="goal-communication" ref={chatBodyRef}>
                  <div className="main-communication">
                    {mainArr.map(
                      (goalItem, gIndex) =>
                        goalItem &&
                        ((goalItem.order < currentOrder &&
                          !goalItem.answer_text) ||
                          (goalItem.order <= currentOrder &&
                            goalItem.answer_text) ||
                          (goalItem.order === currentOrder && !curFlag) ||
                          (goalItem.order === currentOrder &&
                            doneFlag &&
                            curArr.includes("Thanks for your feedback."))) && (
                          <div key={"goalitem-" + gIndex}>
                            <div className="left sentence">
                              <img src={pandaLogo} alt="pandaLogo" />
                              <div
                                className="chat"
                                style={{ ...text_md_semibold }}
                              >
                                {goalItem.question_text === ""
                                  ? goalItem.context
                                  : goalItem.context === ""
                                    ? goalItem.question_text ||
                                      `Q` + (gIndex + 1)
                                    : getQuestion(
                                        goalItem.question_text,
                                        goalItem.context
                                      )}
                              </div>
                            </div>
                            {goalItem.answer_text !== "" &&
                              goalItem.question_text !== "" && (
                                <div className="right sentence">
                                  <div
                                    className="chat"
                                    style={{ ...text_md_semibold }}
                                  >
                                    {goalItem.answer_text}
                                  </div>
                                </div>
                              )}
                          </div>
                        )
                    )}
                    {!typing && curFlag && curArr !== "" && (
                      <div className="left sentence">
                        <img src={pandaLogo} alt="pandaLogo" />

                        <p> {curArr}</p>
                      </div>
                    )}
                    {typing === true && !doneFlag && (
                      <div className={`typing left sentence`}>
                        {text === "" && <img src={pandaLogo} alt="pandaLogo" />}
                        <TypingComponent
                          text={curArr || `Q` + mainArr.length}
                          color={"black"}
                          onTypingStart={() => {}}
                          onTypingFinish={() => {
                            checkScroll();
                            setCurFlag(true);
                            setTyping(false);
                            if (textareaRef.current) {
                              textareaRef.current.focus();
                            }
                            if (
                              context !== "" &&
                              mainArr[mainArr.length - 1].question_text === ""
                            ) {
                              setTimeout(() => {
                                const tempOrder = Math.floor(currentOrder) + 1;
                                const index = originalArr.findIndex(
                                  (item) => item.order === tempOrder
                                );
                                if (originalArr[index]) {
                                  setCurrentOrder(tempOrder);
                                  setMainArr([...mainArr, originalArr[index]]);
                                } else {
                                  setDoneFlag(true);
                                }
                                setTyping(true);
                                checkScroll();
                                setContext("");
                              }, 500);
                            }
                          }}
                        />
                      </div>
                    )}
                    {typing === true && doneFlag && (
                      <div className={`typing left sentence`}>
                        {text === "" && (
                          <img src={pandaLogo} alt="chat-panda" />
                        )}
                        <TypingComponent
                          text={
                            "Thanks for your feedback. To complete the smart interview please click Done."
                          }
                          color={"black"}
                          onTypingStart={() => {}}
                          onTypingFinish={() => {
                            checkScroll();
                            setTimeout(() => {
                              setCurArr(
                                "Thanks for your feedback. To complete the smart interview please click Done."
                              );
                              setCurFlag(true);
                              setTyping(false);
                              if (textareaRef.current) {
                                textareaRef.current.focus();
                              }
                              checkScroll();
                            }, 500);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="inputWrapper">
                    {!doneFlag && (
                      <div className="goal-input-wrap goal-temp">
                        <textarea
                          ref={textareaRef}
                          value={text}
                          onChange={(e) => {
                            setText(e.target.value);
                          }}
                          rows="1"
                          style={{
                            width: "100%",
                            resize: "none",
                          }}
                          placeholder="Type here..."
                        />
                        <img
                          style={{ width: `20px` }}
                          src={sendYellow}
                          onClick={() => {
                            if (text !== "") sendFunc();
                          }}
                          alt="sendYellow"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {goalStatus === "AfterSet" && (
                <div className="goal-change-list">
                  {mainArr.map(
                    (goalChangeItem, itemIndex) =>
                      (goalChangeItem.answer_text ||
                        (originalArr[itemIndex] &&
                          originalArr[itemIndex].answer_text)) && (
                        <div key={"item" + itemIndex}>
                          <div className="goal-change-item">
                            <h3
                              style={{ ...text_md_semibold, fontWeight: 800 }}
                            >
                              Question
                              {"  "}
                            </h3>
                            <p style={text_sm_regular}>
                              {goalChangeItem.context +
                                " " +
                                goalChangeItem.question_text}
                            </p>
                          </div>
                          <div className="goal-change-item">
                            <h3
                              style={{ ...text_md_semibold, fontWeight: 800 }}
                            >
                              Answer
                              {"  "}
                            </h3>
                            <p style={text_sm_regular}>
                              {goalChangeItem.answer_text}
                            </p>
                          </div>
                        </div>
                      )
                  )}
                </div>
              )}
              {goalStatus === "ChangeSet" && (
                <div className="goal-change-list change-set-list">
                  {mainArr.map(
                    (goalChangeItem, itemIndex) =>
                      (goalChangeItem.answer_text ||
                        (originalArr[itemIndex] &&
                          originalArr[itemIndex].answer_text)) && (
                        <div key={"item" + itemIndex}>
                          {" "}
                          <div className="goal-change-item change-set">
                            <div className="goal-change-header">
                              <h3
                                style={{ ...text_md_semibold, fontWeight: 800 }}
                              >
                                Question
                              </h3>
                              <img
                                src={trash}
                                alt="trash"
                                onClick={() => {
                                  deleteAnswer(goalChangeItem.answer_id)(
                                    dispatch
                                  ).then((res) => {
                                    if (res && res.status) {
                                      setApiStatus(res.status);
                                    } else {
                                      let tempMainArr = [...mainArr];
                                      let tempOrgArr = [...originalArr];

                                      tempMainArr.map((item, idx) => {
                                        if (itemIndex < idx) {
                                          item.order -= 1;
                                        }
                                      });
                                      tempOrgArr.map((item, idx) => {
                                        if (itemIndex < idx) {
                                          item.order -= 1;
                                        }
                                      });
                                      tempMainArr.splice(itemIndex, 1);
                                      tempOrgArr.splice(itemIndex, 1);
                                      setMainArr([...tempMainArr]);
                                      setOriginalArr([...tempOrgArr]);
                                    }
                                  });
                                }}
                              />
                            </div>
                            <p style={text_sm_regular}>
                              {goalChangeItem.context +
                                " " +
                                goalChangeItem.question_text}
                            </p>
                            <input
                              type="text"
                              className="form-control"
                              value={goalChangeItem.answer_text}
                              id={"input_answer_" + itemIndex}
                              onChange={(e) => {
                                const tempMainArr = [...mainArr];

                                tempMainArr[itemIndex].answer_text =
                                  e.target.value;
                                setMainArr([...tempMainArr]);
                              }}
                              placeholder={
                                "How important is the weight of an anvil?"
                              }
                            />
                          </div>
                        </div>
                      )
                  )}
                </div>
              )}
            </div>
            {/* Panda behavior */}
            <div className="card" ref={pandaBehaviorRef}>
              <h3 style={{ ...text_lg, fontWeight: 700 }}>Panda behavior</h3>
              <p style={text_sm_regular}>
                What personality would you like the AI Panda character to
                present?
              </p>
              <label style={text_sm_regular}>Panda conversational tone</label>
              <select
                value={pandaBehavior}
                className="form-control"
                onChange={(e) => {
                  setPandaBehavior(e.target.value);
                }}
              >
                {Object.keys(pandaBehaviorList).map((index) => (
                  <option key={"subBehavior-" + index} value={index}>
                    {pandaBehaviorList[index]}
                  </option>
                ))}
              </select>
            </div>
            {/* Questions */}
            <div className="card" ref={questionsRef}>
              <div className="card-header">
                <h3 style={{ ...text_lg, fontWeight: 700 }}>Panda Questions</h3>
                <Button
                  title={"Add Questions"}
                  icon="plus"
                  outline
                  onClickBtn={() => {
                    setUnsaved(true);
                    setQuestions([
                      ...questions,
                      {
                        order: questions.length,
                        text: "",
                        add_or_update: "add",
                      },
                    ]);
                    setUpdateQuestionError(false);
                  }}
                />
              </div>
              <p style={text_sm_regular}>
                Create your own interview questions...
              </p>
              <hr />
              <div className="question-lists">
                {questions.map((question, questionIndex) => (
                  <div
                    key={"question-" + questionIndex}
                    className="questionCard"
                  >
                    <div className="questionCardHeader">
                      <h3
                        style={{
                          ...text_md_semibold,
                          fontWeight: 800,
                          color: globalColor.gray_900,
                        }}
                      >
                        Question #{question.order + 1}
                      </h3>
                      <img
                        src={trash}
                        alt="trash"
                        onClick={() => {
                          if (question.id !== undefined)
                            deleteQuestion(
                              slate.interview_ids[0],
                              question.id
                            )(dispatch).then((res) => {
                              if (res && res.status) {
                                setApiStatus(res.status);
                              }
                            });
                          let tempQuestions = [...questions];
                          tempQuestions.map((question, idx) => {
                            if (questionIndex < idx) {
                              question.order -= 1;
                            }
                          });
                          tempQuestions.splice(questionIndex, 1);
                          setQuestions([...tempQuestions]);
                        }}
                      />
                    </div>
                    <div className="questionCardContent">
                      <div className="form-group">
                        <label style={text_sm_regular}>
                          Write a question (required)
                        </label>
                        <input
                          type="text"
                          className={`form-control ${updateQuestionError && question.text.length < 4 && "form-validation"}`}
                          value={question.text}
                          onChange={(e) => {
                            const tempQuestions = [...questions];
                            tempQuestions[questionIndex].text = e.target.value;
                            setQuestions([...tempQuestions]);
                            setUpdateQuestionError(false);
                          }}
                          placeholder={
                            "How important is the weight of an anvil?"
                          }
                        />
                        {updateQuestionError && question.text.length < 4 && (
                          <label style={{ margin: "0", color: "red" }}>
                            Question text field must be at least 4 chracteres in
                            length
                          </label>
                        )}
                      </div>
                      <div className="questionCardArrow">
                        {questionIndex !== questions.length - 1 && (
                          <img
                            src={ArrowDown}
                            alt="arrow Icon"
                            onClick={() => {
                              updateQuestionOrder(1, questionIndex);
                            }}
                          />
                        )}
                        {questionIndex !== 0 && (
                          <img
                            src={ArrowUp}
                            alt="arrow Icon"
                            onClick={() => {
                              updateQuestionOrder(-1, questionIndex);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {questions.length === 0 && <p>There are no questions.</p>}
              </div>
              <div className="question-btn">
                <Button
                  title={"Add Questions"}
                  icon="plus"
                  outline
                  onClickBtn={() => {
                    setUnsaved(true);
                    setQuestions([
                      ...questions,
                      {
                        order: questions.length,
                        text: "",
                        add_or_update: "add",
                      },
                    ]);
                    setUpdateQuestionError(false);
                  }}
                />
              </div>
            </div>
            {/* Incentive */}
            <div className="card" ref={incentiveRef}>
              <div className="card-header">
                <h3 style={{ ...text_lg, fontWeight: 700 }}>Incentive</h3>
              </div>
              <p style={text_sm_regular}>
                Offer a compelling incentive to entice more respondents to
                participate in the Smart Interview
              </p>
              {/* <div className="coupon-main">
                <img
                  className="coupon"
                  src={incentive ? coupon : sadCoupon}
                  alt="coupon"
                />
              </div> */}
              {!incentive && incentiveId && despIncentive && (
                <div className="wrapper-incentive">
                  <div className="coupon-incentive">
                    <img src={gift} alt="gift" className="gift" />
                    <div className="coupon-incentive-content">
                      <div style={text_sm_regular}>{despIncentive}</div>
                    </div>
                  </div>
                  <div style={{ alignContent: "center", lineHeight: "40px" }}>
                    <img src={arrowLeft} alt="arrowleft" />
                    &nbsp;&nbsp;&nbsp;
                    {getIncentive === "all"
                      ? `All respondents will earn this incentive`
                      : `No incentive is offered`}
                  </div>
                </div>
              )}
              {/* {!incentive && incentiveId && despIncentive && (
                <div style={text_sm_regular}>
                  Email address is{" "}
                  <span style={{ ...text_sm_regular, fontWeight: 900 }}>
                    required
                  </span>
                </div>
              )} */}
              {incentive && (
                <div className="create-incentive">
                  <div className="create-incentive-radio-group">
                    <h3 style={{ ...text_md_semibold, fontWeight: 900 }}>
                      Who gets the incentive?
                    </h3>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault3"
                        id="flexRadioDefault11"
                        value={"none"}
                        checked={getIncentive === "none"}
                        onChange={(e) => {
                          handleGetIncentive("none");
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault11"
                      >
                        No incentive is offered
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault3"
                        id="flexRadioDefault21"
                        value={"all"}
                        checked={getIncentive === "all"}
                        onChange={(e) => {
                          handleGetIncentive("all");
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault21"
                      >
                        Every respondent receives the incentive
                      </label>
                    </div>
                    {/* <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault3"
                        id="flexRadioDefault1"
                        value={"Winning respondent"}
                        checked={getIncentive === "Winning respondent"}
                        onChange={handleGetIncentive}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        Winning respondents will receive the incentive
                      </label>
                      {getIncentive === "Winning respondent" && (
                        <div className="winning-respondent">
                          <input
                            className="form-control form-control-custom-amount"
                            value={3}
                            onChange={(e) => {}}
                          />
                          randomly selected respondents will win and receive the
                          incentive
                        </div>
                      )}
                    </div> */}
                  </div>
                  <div className="create-incentive-radio-group">
                    <h3 style={{ ...text_md_semibold, fontWeight: 900 }}>
                      Incentive Description &nbsp;
                      <span
                        style={{
                          fontWeight: 400,
                          fontStyle: "italic",
                          color: "gray",
                        }}
                      >
                        {` (max 120 characters)`}
                      </span>
                    </h3>
                    <textarea
                      className={`form-control ${createIncentiveFlag && despIncentive.length < 4 && "form-validation"}`}
                      rows={2}
                      maxLength={120}
                      onChange={(e) => {
                        setDespIncentive(e.target.value);
                      }}
                      value={despIncentive}
                      placeholder={
                        "Create your own incentive! Examples could be a shout out on social media, dinner with you, a paid trip for two, etc."
                      }
                      disabled={`${getIncentive !== "all" ? "disabled" : ""}`}
                    />
                    {createIncentiveFlag &&
                      despIncentive.length < 4 &&
                      getIncentive === "all" && (
                        <label style={{ margin: "0", color: "red" }}>
                          Description field must be at least 4 chracteres in
                          length
                        </label>
                      )}
                  </div>
                  {/* <div className="create-incentive-radio-group">
                    <h3 style={{ ...text_md_semibold, fontWeight: 900 }}>
                      Choose incentive type:
                    </h3>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault2"
                        id="flexRadioDefault2"
                        value={"giftCard"}
                        checked={incentiveType === "giftCard"}
                        onChange={handleIncentiveType}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Gift Card
                      </label>
                      {incentiveType === "giftCard" && (
                        <div>
                          <label
                            style={{ ...text_sm_regular, marginBottom: "6px" }}
                          >
                            Vendor
                          </label>
                          <CustomSelect
                            options={vendorOptions}
                            placeholder="Select an option"
                          />
                          <label
                            style={{ ...text_sm_regular, marginBottom: "6px" }}
                          >
                            Amount
                          </label>
                          <div className="amount-group">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault4"
                                id="flexRadioDefault4"
                                value={"10"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault4"
                              >
                                $10
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault4"
                                id="flexRadioDefault4"
                                value={"15"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault4"
                              >
                                $15
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault4"
                                id="flexRadioDefault4"
                                value={"20"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault4"
                              >
                                $20
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault4"
                                id="flexRadioDefault4"
                                value={"35"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault4"
                              >
                                $35
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault4"
                                id="flexRadioDefault4"
                                value={"50"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault4"
                              >
                                $50
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault4"
                                id="flexRadioDefault4"
                                value={"50"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault4"
                              >
                                Other amount
                              </label>
                            </div>
                            <input
                              type="number"
                              className="form-control form-control-custom-amount"
                              value={"question.description"}
                              onChange={(e) => {}}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault2"
                        id="flexRadioDefault2"
                        value={"Donation"}
                        checked={incentiveType === "Donation"}
                        onChange={handleIncentiveType}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Donation
                      </label>
                      {incentiveType === "Donation" && (
                        <div>
                          <label style={text_sm_regular}>
                            Choose an organization to donate
                          </label>
                          <CustomSelect
                            options={donationOptions}
                            placeholder="Select an option"
                          />
                          <label style={text_sm_regular}>Amount</label>
                          <div className="amount-group">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault4"
                                id="flexRadioDefault4"
                                value={"10"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault4"
                              >
                                $10
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault4"
                                id="flexRadioDefault4"
                                value={"15"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault4"
                              >
                                $15
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault4"
                                id="flexRadioDefault4"
                                value={"20"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault4"
                              >
                                $20
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault4"
                                id="flexRadioDefault4"
                                value={"35"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault4"
                              >
                                $35
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault4"
                                id="flexRadioDefault4"
                                value={"50"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault4"
                              >
                                $50
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault4"
                                id="flexRadioDefault4"
                                value={"50"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault4"
                              >
                                Other amount
                              </label>
                            </div>
                            <input
                              type="number"
                              className="form-control form-control-custom-amount"
                              value={"question.description"}
                              onChange={(e) => {}}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault2"
                        id="flexRadioDefault2"
                        value={"Plant a Tree"}
                        checked={incentiveType === "Plant a Tree"}
                        onChange={handleIncentiveType}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Plant a Tree!
                      </label>
                      {incentiveType === "Plant a Tree" && (
                        <p style={text_sm_regular}>
                          {" "}
                          A tree will be planted on the respondent’s behalf
                          through OneTreePlanet.Org.
                        </p>
                      )}
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault2"
                        id="flexRadioDefault2"
                        value={"Create your own incentive"}
                        checked={incentiveType === "Create your own incentive"}
                        onChange={handleIncentiveType}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Create your own incentive
                      </label>
                      {incentiveType === "Create your own incentive" && (
                        <div className="form-group">
                          <label style={text_sm_regular}>
                            Description of the incentive
                          </label>
                          <textarea
                            className="form-control"
                            rows={4}
                            onChange={(e) => {}}
                            placeholder={
                              "Create your own incentive! Examples could be a shout out on social media, dinner with you, a paid trip for two, etc."
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div> */}
                  <div className="create-incentive-btn">
                    <Button
                      title="Cancel"
                      outline
                      onClickBtn={() => {
                        setIncentive(null);
                      }}
                    />
                    <Button
                      title={
                        incentiveId ? "Change Incentive" : "Create Incentive"
                      }
                      disable={despIncentive === ""}
                      onClickBtn={() => {
                        if (despIncentive !== "") {
                          if (incentiveId) updateIncentive();
                          else createIncentive();
                        }
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="question-btn">
                {incentive ? (
                  <></>
                ) : incentiveId && despIncentive ? (
                  <Button
                    title={"Change Incentive"}
                    icon="pen"
                    outline
                    onClickBtn={() => {
                      setIncentive("coupon");
                    }}
                  />
                ) : (
                  <Button
                    title={"Add Incentive"}
                    icon="plus"
                    outline
                    onClickBtn={() => {
                      setIncentive("coupon");
                    }}
                  />
                )}
              </div>
            </div>
            {/* Exit message */}
            <div className="card" ref={exitMessageRef}>
              <h3 style={{ ...text_lg, fontWeight: 700 }}>Exit message</h3>
              <p style={text_sm_regular}>
                Messaging on the final screen, thanking the interviewee for
                participating in the interview
              </p>
              <input
                type="text"
                className={`form-control ${inputFlag === 1 && exitMessage.length < 4 && "form-validation"}`}
                value={exitMessage}
                onChange={(e) => {
                  setUnsaved(true);
                  setExitMessage(e.target.value);
                }}
                placeholder={"Thank you!"}
              />
              {inputFlag === 1 && exitMessage.length < 4 && (
                <p className="required-html">
                  Exit Message is required and must be at least four characters
                  in length.
                </p>
              )}
            </div>
            {/* Respondents */}
            <div className="card" ref={respondentsRef}>
              <h3 style={{ ...text_lg, fontWeight: 700 }}>Respondents</h3>
              <p style={text_sm_regular}>
                How many respondents would you like to participate in this
                interview?
              </p>
              <div className="form-group">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault7"
                    id="flexRadioDefault1"
                    value={"unlimit"}
                    checked={limitRadio === "unlimit"}
                    onChange={handleLimitRadio}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Unlimited respondents – keep running interviews until I
                    manually deactivate this Slate
                  </label>
                </div>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault7"
                  id="flexRadioDefault2"
                  value={"limit"}
                  checked={limitRadio === "limit"}
                  onChange={handleLimitRadio}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  Limited respondents
                </label>
              </div>

              {limitRadio === "limit" && (
                <div className="form-group number">
                  <input
                    type="text"
                    className="form-control"
                    value={maxRespondent}
                    onChange={(e) => {
                      setUnsaved(true);
                      setMaxRespondent(e.target.value);
                    }}
                  />
                  respondents will complete smart interviews
                </div>
              )}
            </div>
          </MainSetup>
        </InterviewSetup>
      </div>
      <Footer>
        <div>
          <Button
            icon={"trash"}
            title={"Delete"}
            outline
            onClickBtn={() => {
              deleteSlate(slate.id)(dispatch)
                .then((res) => {
                  if (res && res.status) {
                    setApiStatus(res.status);
                  } else {
                    setDelToast(1);
                    navigate("/creator-dashboard/smart-interviews/");
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          />
          <Button
            icon={"save"}
            title={"Save Draft"}
            margin={"true"}
            onClickBtn={() => {
              submitFunc();
            }}
          />
          <Button
            icon={"active"}
            title={"Activate"}
            disable={unCompletedCounts > 0 || activatedStatus ? true : false}
            onClickBtn={() => {
              if (unCompletedCounts > 0 || activatedStatus) {
              } else {
                if (unCompletedCounts === 0) {
                  handleActivate();
                } else {
                  setCompletedFlag(1);
                }
              }
            }}
          />
          {toast !== -1 && (
            <SaveToast
              closeToast={() => {
                setToast(-1);
              }}
              toast={toast}
              pageType={"SmartInterview"}
            />
          )}
          {delToast !== -1 && (
            <SaveToast
              closeToast={() => {
                setDelToast(-1);
              }}
              text="Deleted successfully!"
              toast={toast}
              pageType={"SmartInterview"}
            />
          )}
        </div>{" "}
      </Footer>

      {saveCancelFlag !== -1 && (
        <SaveCancelModal
          closeModal={() => {
            setSaveCancelFlag(-1);
          }}
          text="Deleted successfully!"
          saveModal={(e) => {
            if (e === 1) navigate("/creator-dashboard/smart-interviews");
            else {
              submitFunc(1);
            }
          }}
        />
      )}
      {activateModal !== -1 && (
        <ActivateModal
          closeModal={() => {
            setActivateModal(-1);
          }}
          text=""
          title={
            window.location.protocol +
            "//" +
            window.location.host +
            "/smartinterviews/" +
            currentSlate.code
          }
          saveModal={(e) => {
            if (e === 1) navigate("/creator-dashboard/smart-interviews");
            else {
              submitFunc(1);
            }
          }}
        />
      )}
      {apiStatus === 404 && (
        <NotFoundErrorModal
          closeModal={() => {
            setApiStatus(200);
          }}
          title=""
          description=""
          saveModal={(obj) => {}}
        />
      )}
      {apiStatus === 500 && (
        <ServerErrorModal
          closeModal={() => {
            setApiStatus(200);
          }}
          title=""
          description=""
          saveModal={(obj) => {}}
        />
      )}
    </DashboardWrapper>
  );
}

const DashboardWrapper = styled.div`
  .scroll-container {
    scroll-padding-top: 80px; /* Adjust as needed */
  }
  .account-header {
    min-width: 524px;
  }
  .main-wrapper {
    min-width: 524px;
    padding: 16px 32px;
    .filter-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 20px;
      color: var(--gray-700, #344054);
      font-family: Figtree;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      input {
        border-radius: 8px;
        padding: 10px 14px;
        border: 1px solid var(--gray-300, #d0d5dd);
        background: var(--base-white, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
    }
  }
  .body-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    max-width: 1065px;
    min-width: 524px;
    &.transcript-main {
      gap: 20px;
      .card-main {
        margin: 0px !important;
      }
    }
  }
  .card {
    .card-header {
      border: 0px;
      background: none;
    }
    .card-body {
      p {
        margin-top: 12px;
        color: ${globalColor.gray_500}!important;
        font-style: italic;
        span {
          font-style: normal;
          color: ${globalColor.gray_900};
          font-weight: bold;
        }
      }
    }
  }
  @media (max-width: 880px) {
    .main-wrapper {
      padding: 16px;
    }
  }
  .transcript-card {
    border-radius: "16px";
    max-width: 688px;
    border: "1px solid ${globalColor.gray_200}";
    padding: "28px";
    background: ${globalColor.base_white};
    margin-bottom: 25px;
    margin-right: 16px;
    .transcript-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid ${globalColor.gray_200};
      padding: 18px 24px;
      h4,
      p {
        margin: 0px;
      }
    }
    .transcript-main {
      padding: 4px 24px;
      h5 {
        paddig-left: 12px;
      }
      .transcript-chat {
        padding-bottom: 4px;
        h4 {
          font-weight: bold;
          color: ${globalColor.orange_500};
          margin-bottom: 0px;
          span {
            font-weight: normal;
            padding-left: 12px;
            color: ${globalColor.gray_500};
          }
        }
        h4.ACME {
          color: ${globalColor.primary_500};
        }
        p {
          margin-bottom: 0px;
        }
      }
    }
    .transcript-footer {
      .transcript-iterview-status {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        position: relative;
        justify-content: center;
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          width: 118px;
          height: 1px;
          background-color: ${globalColor.gray_300};
          @media (max-width: 998px) {
            height: 0px;
          }
        }
        &::before {
          left: 24px;
        }
        &::after {
          right: 24px;
        }
      }
      .user-session-details {
        padding: 16px 24px;
        .usd-title {
          margin: 0px;
          padding: 0px;
          margin-right: 4px;
          cursor: pointer;
          img {
            margin-left: 6px;
            margin-top: -3px;
            &.down {
              transform: rotate(180deg);
            }
          }
        }
        .usd-lists {
          display: flex;
          gap: 8px;
          margin-top: 8px;
          flex-wrap: wrap;
          div {
            background: ${globalColor.gray_100};
            border-radius: 4px;
            padding: 0px 8px;
            color: ${globalColor.gray_500};
            span {
              color: ${globalColor.gray_900};
            }
          }
        }
      }
    }
  }
  .card-header {
    display: flex;
  }
  .search-box input {
    padding-left: 37px !important;
  }
  .search-box {
    position: relative;
    display: flex;
    align-items: center;
  }
  .search-box .search-icon {
    position: absolute;
    left: 10px; /* Adjust to fit the input */
    width: 20px; /* Adjust based on icon size */
    height: 20px; /* Adjust based on icon size */
  }
`;
const InterviewSetup = styled.div`
  display: flex;
  padding: 30px 35px;
  padding-right: 0;
  gap: 35px;
  height: calc(100vh - 120px);
  max-width: 1095px;
  .circle {
    width: 6px; /* Adjust the size of the circle */
    height: 6px; /* Adjust the size of the circle */
    background-color: #f04438; /* Red color */
    border-radius: 50%; /* Makes the shape circular */
    margin-right: 10px; /* Space after the circle, adjust as needed */
    padding: 5px;
    gap: 0px;
    display: inline-block; /* Allows the circle to be inline with text or other elements */
  }
`;
const StepBar = styled.div`
  img.uncheckIcon {
    filter: invert(1);
  }
  .uncomplete-err {
    border: 1px solid ${globalColor.gray_300};
    padding: 12px 8px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    gap: 8px;
    margin-top: 30px;
    img {
      width: 20px;
      height: 20px;
    }
    h4 {
      margin: 0px;
      font-size: 14px;
      line-height: 20px;
      color: ${globalColor.gray_700};
      font-family: "Figtree";
    }
  }
  width: 188px;
  .stepBtn {
    position: relative;
    display: flex;
    padding: 8px 10px;
    border-radius: 4px;
    align-items: center;

    &.selected,
    &:hover {
      background-color: ${globalColor.primary_200};
    }
    h3 {
      margin: 0px;
      padding-left: 12px;
      line-height: 24px;
    }
    cursor: pointer;
    margin-bottom: 28px;
  }
`;
const Footer = styled.div`
  background: white;
  width: calc(100% - 300px);
  padding: 13px 0px;
  position: fixed;
  bottom: 0px;
  & > div {
    display: flex;
    justify-content: end;
    max-width: 1140px;
    padding-right: 35px;
  }
`;
const MainSetup = styled.div`
  width: calc(100% - 188px);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 500px;
  .card {
    padding: 20px 32px;
    margin-right: 8px;
    background: white;
    border-radius: 8px;
    border: 0px;
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    margin-bottom: 24px;
    .card-header {
      display: flex;
      justify-content: space-between;
      padding: 0px;
      .outline {
        margin: 0px !important;
      }
    }
    .coupon {
      width: 342px;
      height: 104px;
    }
    .goal-change-list {
      padding: 12px;
      .goal-change-item {
        margin-bottom: 8px;
        h3 {
          span {
            color: ${globalColor.gray_500};
          }
        }
        &:nth-child(odd) h3,
        &:nth-child(odd) p {
          color: ${globalColor.primary_700};
        }
        &:nth-child(even) h3 &:nth-child(even) p {
          color: ${globalColor.gray_700};
        }
        .goal-change-header {
          display: flex;
          justify-content: space-between;
          img {
            width: 20px;
            cursor: pointer;
            height: 20px;
          }
        }
      }
      &.change-set-list {
        .goal-change-item.change-set {
          background-color: ${globalColor.gray_100};
          border-radius: 8px;
          padding: 16px;
        }
      }
    }
    .goal-communication {
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: space-between;
      border-radius: 20px;
      background-color: white;
      border: 1px solid ${globalColor.gray_400};
      padding: 20px;
      max-height: 310px;
      overflow-y: auto;
      .goal-input-wrap {
        position: relative;
        textarea {
          border-radius: 20px;
          padding: 12px 24px;
          padding-right: 48px;
        }
        img {
          position: absolute;
          right: 24px;
          bottom: 21px;
          cursor: pointer;
        }
      }
      &.thankyou {
        h2,
        label {
          text-align: center;
        }
        h2 {
          font-size: 30px;
          line-height: 38px;
          font-weight: bold;
          font-family: "Figtree";
          margin: 0px;
        }
        input.form-control {
          max-width: 450px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .doneInternal {
        cursor: pointer;
        position: absolute;
        right: 39px;
        bottom: 49px;
        border-radius: 12px;
        color: white;
        background-color: ${globalColor.primary_600};
        padding: 12px 16px;
      }
      .sentence {
        padding-bottom: 12px;
        &.left {
          display: flex;
          gap: 16px;
          img {
            height: 50px;
            margin-top: auto;
          }
          p,
          .typing-p {
            margin: 0px;
            background-color: #f9fafb;
            padding: 12px 16px;
            font-size: 16px;
            line-height: 24px;
            color: black;
            max-width: 306px;
            word-break: break-word;
            border-radius: 12px;
          }
          .chat {
            padding: 12px 16px;
            border: 0px;
            border-radius: 16px;
            border-bottom-left-radius: 0px;
            background-color: ${globalColor.gray_50};
            max-width: 306px;
          }
        }
        &.right {
          display: flex;
          justify-content: end;
          gap: 16px;
          .chat {
            padding: 12px 16px;
            border: 0px;
            border-radius: 16px;
            background-color: ${globalColor.primary_600};
            border-bottom-right-radius: 0px;
            color: white;
            max-width: 420px;
          }
        }
      }
    }
    .goal-result {
      display: flex;
      gap: 16px;
      img {
      }
      div {
        padding: 12px 16px;
        border: 0px;
        border-radius: 16px;
        width: 380px;
        background-color: ${globalColor.gray_50};
      }
    }
    .goal-list {
    }
  }
  .card:last-of-type {
    margin-bottom: 100px; /* Adjust as needed for extra space at the end */
  }
  .question-btn {
    display: flex;
    justify-content: end;
  }
  .question-lists {
    .questionCard {
      background: ${globalColor.gray_100};
      border-radius: 8px;
      padding: 16px;
      border: 0px;
      margin-bottom: 16px;
      .questionCardArrow {
        margin-top: auto;
        img {
          height: 20px;
          width: 20px;
          margin-top: auto;
          margin-bottom: 13px;
          cursor: pointer;
        }
      }
      .questionCardHeader {
        display: flex;
        justify-content: space-between;
        img {
          width: 20px;
          cursor: pointer;
          height: 20px;
        }
      }
      .questionCardContent {
        display: flex;
        justify-content: space-between;
        .form-group {
          width: calc(100% - 60px);
        }
      }
    }
    .form-check-input:checked[type="radio"] {
      border: 1px solid var(--primary-600, #1693c7);
      background: var(--primary-50, #e7f7ff);
      background-image: url(${radio_check});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 6px 6 px;
    }
    .form-check-input:checked {
      background-color: #1693c7;
      border-color: #1693c7;
    }
  }
  .form-control.form-validation {
    border-color: red;
    margin-bottom: 3px;
  }
  p.required-html {
    color: #d92d20;
    font-size: 14px;
    line-height: 24px;
  }
  .form-group.number {
    input.form-control {
      width: 55px;
      display: inline-block;
      margin-right: 10px;
    }
  }
  .wrapper-incentive {
    display: flex;
    gap: 16px;
    padding: 32px 0px;
  }
  .coupon-incentive {
    background-image: url(${Subtract});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 390px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    gap: 15px;
    img.gift {
      width: 48px;
      height: 48px;
    }
    .coupon-incentive-content {
      max-width: 245px;
      border-left: 2px solid rgba(102, 102, 102, 0.2);
      margin-left: 1px;
      border-left-style: dashed;
      padding-left: 10px;
      display: flex;
      & > div {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
  .create-incentive {
    padding: 20px 24px;
    border-radius: 8px;
    border: 0px;
    background: ${globalColor.gray_50};
    .create-incentive-radio-group {
      margin-bottom: 32px;
    }
    .create-incentive-btn {
      display: flex;
      justify-content: end;
    }
    .vendor-select {
      width: 320px;
    }
    .amount-group {
      display: flex;
      gap: 16px;
      .form-check {
        margin-top: auto;
        margin-bottom: auto;
      }
      .form-control-custom-amount {
        display: inline-block;
        width: 60px;
      }
    }
  }
  .winning-respondent {
    padding-top: 8px;
    input {
      display: inline-block;
      width: 40px;
      margin-right: 8px;
    }
  }
`;
