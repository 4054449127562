import http from "../config/http-common";
import { getCookie } from "../config/common";
import { Buffer } from "buffer";
import "../setupInterceptors";

const createAccount = (email, company, password) => {
  return http.post(`/v1/users/external`, {
    email: email,
    company_name: company,
    password: password,
  });
};

const sendResetPassword = (email) => {
  return http.get(`/v1/users/verification/password?email=${email}`);
};

const resendVerifyEmail = (code) => {
  return http.get(`/v1/users/verification/resend/` + code);
};

const setNewPassword = (code, email, password) => {
  return http.put(`/v1/users/verification/password/${code}`, {
    email: email,
    password: password,
  });
};

const getToken = (email, password) => {
  const credentials = Buffer.from(`${email}:${password}`).toString("base64");
  const authorizationHeader = `Basic ` + credentials;
  const accessKID = "54bb2165-71e1-41a6-af3e-7da4a0e1e2c1";

  return http.post(
    `/v1/auth/login/${accessKID}`,
    {},
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

const getAuthRefreshToken = () => {
  const refresh_token = getCookie("refresh_token");

  if (!refresh_token || refresh_token === null)
    window.location.href = "/creator-dashboard/login";

  const authorizationHeader = `Bearer ` + refresh_token;
  const refreshKID = "28B48444-F87E-487E-A504-61FB68A19149";

  return http.post(
    `/v1/auth/refresh/${refreshKID}`,
    {},
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

const putWelcome = (user_id, obj, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;
  obj.is_onboarded = true;

  return http.put(
    `/v1/users/${user_id}`,
    { ...obj },
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

// =============== Slates ===============

const getSlates = (page, rows, query, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.get(`/v1/slates?page=${page}&rows=${rows}${query}`, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const getSlate = (id, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.get(`/v1/slates/${id}`, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const getSlateUrlCode = (id, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.get(`/v1/slates/${id}/url`, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const updateSlate = (id, obj, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.put(`/v1/slates/${id}`, obj, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const addSlate = (name, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.post(
    `/v1/slates`,
    { name: name },
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

const deleteSlate = (id, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.delete(`/v1/slates/${id}`, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const getInterview = (sId, interviewId, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.get(`/v1/slates/${sId}/interviews/${interviewId}`, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const saveInterview = (sId, interviewId, obj, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.put(`/v1/slates/${sId}/interviews/${interviewId}`, obj, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const startTestDrive = (url_code, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.get(`/v1/urls/${url_code}/transcripts/start-test-drive`, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const advanceTestDrive = (url_code, obj, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.post(`/v1/urls/${url_code}/transcripts/advance-test-drive`, obj, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const getTranscripts = (interview_id, page, rows, query, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.get(
    `/v1/interviews/${interview_id}/transcripts?page=${page}&rows=${rows}${query}`,
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

const getTranscript = (interview_id, transcripts_id, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.get(
    `/v1/interviews/${interview_id}/transcripts/${transcripts_id}`,
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

const postTranscript = (url_code, obj, session_token) => {
  const authorizationHeader = `Bearer ` + session_token;

  return http.post(`/v1/urls/${url_code}/transcripts`, obj, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const updateTranscript = (url_code, transcript_id, obj, session_token) => {
  const authorizationHeader = `Bearer ` + session_token;

  return http.put(`/v1/urls/${url_code}/transcripts/${transcript_id}`, obj, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const createProspectSession = (url_code) => {
  const sessionKID = "58E64391-93E0-4D24-8698-2B83703ABA03";

  return http.post(
    `/v1/auth/${url_code}/create-prospect-session/${sessionKID}`,
    {}
  );
};

const getOnboardTranscript = (interview_id, access_token) => {
  const authorizationHeader = `Bearer ${access_token}`;

  return http.get(`/v1/interviews/${interview_id}/transcripts/onboard`, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const postOnboardTranscript = (
  interview_id,
  obj,
  access_token,
  session_token
) => {
  const authorizationHeader = `Bearer ${access_token}`;
  const sessionToken = `Bearer ` + session_token;

  return http.post(`/v1/interviews/${interview_id}/transcripts/onboard`, obj, {
    headers: {
      Authorization: authorizationHeader,
      "X-Auth-Token": sessionToken,
    },
  });
};

const updateOnboardTranscript = (
  interview_id,
  transcript_id,
  obj,
  access_token,
  session_token
) => {
  const authorizationHeader = `Bearer ${access_token}`;
  const sessionToken = `Bearer ` + session_token;

  return http.put(
    `/v1/interviews/${interview_id}/transcripts/${transcript_id}/onboard`,
    obj,
    {
      headers: {
        Authorization: authorizationHeader,
        "X-Auth-Token": sessionToken,
      },
    }
  );
};

const updateCompletedOnboardTranscript = (
  interview_id,
  transcript_id,
  obj,
  access_token,
  session_token
) => {
  const authorizationHeader = `Bearer ${access_token}`;
  const sessionToken = `Bearer ${session_token}`;

  return http.put(
    `/v1/interviews/${interview_id}/transcripts/${transcript_id}`,
    obj,
    {
      headers: {
        Authorization: authorizationHeader,
        "X-Auth-Token": sessionToken,
      },
    }
  );
};

const finishTranscript = (transcript_id, email, session_token) => {
  const authorizationHeader = `Bearer ${session_token}`;

  return http.put(
    `/v1/transcripts/${transcript_id}/finish`,
    { email },
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

const createOnboardProspectSession = (slate_id) => {
  const sessionKID = "58E64391-93E0-4D24-8698-2B83703ABA03";

  return http.post(
    `/v1/auth/internal/${slate_id}/create-prospect-session/${sessionKID}`,
    {}
  );
};

const updateSlateActivate = (sId, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.put(
    `/v1/slates/${sId}/url`,
    {},
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

const getQuestion = (interviewId, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.get(`/v1/interviews/${interviewId}/questions`, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const getQuestionQuery = (interviewId, questionId, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.get(`/v1/interviews/${interviewId}/questions/${questionId}`, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const addQuestion = (interviewId, obj, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.post(`/v1/interviews/${interviewId}/questions`, obj, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const updateQuestion = (interviewId, questionId, obj, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.put(
    `/v1/interviews/${interviewId}/questions/${questionId}`,
    obj,
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

const deleteQuestion = (interviewId, questionId, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.delete(`/v1/interviews/${interviewId}/questions/${questionId}`, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

// =============== Accounts ===============

const getAccountsData = (page = 0, rows = 10) => {
  return http.get(`/v1/accounts?page=${page}&rows=${rows}`);
};

const getAccount = (id) => {
  return http.get(`/v1/accounts/${id}`);
};

const postAccount = (obj, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.post(
    `/v1/admin/users/owner`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

const deleteAccount = (company_id, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.delete(`/v1/admin/companies/${company_id}`, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const deleteAdminUser = (user_id, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.delete(`/v1/admin/users/${user_id}`, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const putAccount = (id, obj, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.put(
    `/v1/admin/users/${id}`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

// =============== Users ===============

const getUsers = (company_id, page = 0, rows = 10, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.get(
    `/v1/companies/${company_id}/users?page=${page}&rows=${rows}`,
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

const getUser = (company_id, id, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.get(`/v1/companies/${company_id}/users/${id}`, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const postUser = (company_id, obj, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.post(
    `/v1/companies/${company_id}/users`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

const putUser = (company_id, id, obj, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.put(
    `/v1/companies/${company_id}/users/${id}`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

const deleteUser = (company_id, id, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.delete(`/v1/companies/${company_id}/users/${id}`, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const postCollaborator = (company_id, obj, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.post(
    `/v1/companies/${company_id}/collaborators`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

const transferOwnership = (
  company_id,
  collaborator_id,
  owner_id,
  access_token
) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.put(
    `/v1/companies/${company_id}/ownership`,
    { collaborator_id, owner_id },
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

// =============== GeoTime ===============

const getCountries = (access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.get(`/v1/countries`, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const getTimezones = (access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.get(`/v1/time-zones`, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

// =============== Company ===============

const getOneCompany = (id, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.get(`/v1/companies/${id}`, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const putOneCompany = (id, obj, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.put(`/v1/companies/${id}`, obj, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const uploadLogo = (id, obj, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.post(`/v1/companies/${id}/image`, obj, {
    headers: {
      Authorization: authorizationHeader,
      "Content-Type": "multipart/form-data",
    },
  });
};

const deleteAnswer = (id, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.delete(`/v1/answers/${id}`, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const getInterviewIncentive = (interviewId, incentiveId, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.get(`/v1/interviews/${interviewId}/incentives/${incentiveId}`, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const postInterviewIncentive = (interviewId, obj, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.post(`/v1/interviews/${interviewId}/incentives`, obj, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const putInterviewIncentive = (interviewId, incentiveId, obj, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.put(
    `/v1/interviews/${interviewId}/incentives/${incentiveId}`,
    obj,
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

const deleteInterviewIncentive = (interviewId, incentiveId, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.delete(
    `/v1/interviews/${interviewId}/incentives/${incentiveId}`,
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

// =============== Admin ===============

const adminLogin = (email, password) => {
  const credentials = Buffer.from(`${email}:${password}`).toString("base64");
  const authorizationHeader = `Basic ` + credentials;
  const accessKID = "54bb2165-71e1-41a6-af3e-7da4a0e1e2c1";

  return http.post(
    `/v1/auth/admin/login/${accessKID}`,
    {},
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

// =============== Companies (Admin) ===============

const getCompaniesData = (page = 0, rows = 10, query, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.get(`/v1/admin/companies?page=${page}&rows=${rows}${query}`, {
    headers: {
      Authorization: authorizationHeader,
    },
  });
};

const addCompany = (data) => {
  const email = JSON.parse(localStorage.getItem("user")).email;
  const access_token = `Bearer ` + getCookie("access_" + email);

  return http.post(`/v1/admin/companies`, data, {
    headers: {
      Authorization: access_token,
    },
  });
};

const deleteCompany = (company_id) => {
  const email = JSON.parse(localStorage.getItem("user")).email;
  const access_token = `Bearer ` + getCookie("access_" + email);

  return http.delete(`/v1/admin/companies/${company_id}`, {
    headers: {
      Authorization: access_token,
    },
  });
};

const spoofTokens = (company_id, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.post(
    `/v1/auth/admin/spoof`,
    { company_id: company_id },
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

const postCollaboratorAdmin = (company_id, obj, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.post(
    `/v1/admin/companies/${company_id}/collaborators`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

const transferOwnershipAdmin = (collaborator_id, owner_id, access_token) => {
  const authorizationHeader = `Bearer ` + access_token;

  return http.put(
    `/v1/admin/users/ownership`,
    { collaborator_id, owner_id },
    {
      headers: {
        Authorization: authorizationHeader,
      },
    }
  );
};

const ApiServices = {
  createAccount,
  putWelcome,
  resendVerifyEmail,
  sendResetPassword,
  setNewPassword,
  getToken,
  getAuthRefreshToken,
  getSlate,
  getSlateUrlCode,
  getSlates,
  addSlate,
  deleteSlate,
  getAccountsData,
  getAccount,
  postAccount,
  deleteAccount,
  deleteAdminUser,
  putAccount,
  getUsers,
  getUser,
  postUser,
  putUser,
  deleteUser,
  postCollaborator,
  transferOwnership,
  getInterview,
  saveInterview,
  updateSlate,
  getQuestion,
  getQuestionQuery,
  addQuestion,
  updateQuestion,
  deleteQuestion,
  updateSlateActivate,
  startTestDrive,
  advanceTestDrive,
  postTranscript,
  getTranscripts,
  getTranscript,
  updateTranscript,
  createProspectSession,
  getOnboardTranscript,
  postOnboardTranscript,
  updateOnboardTranscript,
  updateCompletedOnboardTranscript,
  finishTranscript,
  createOnboardProspectSession,
  getCountries,
  getTimezones,
  getCompaniesData,
  getOneCompany,
  putOneCompany,
  uploadLogo,
  deleteAnswer,
  postInterviewIncentive,
  putInterviewIncentive,
  getInterviewIncentive,
  deleteInterviewIncentive,
  adminLogin,
  spoofTokens,
  postCollaboratorAdmin,
  transferOwnershipAdmin,
};

export default ApiServices;
