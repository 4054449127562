import React, { useEffect, useState } from "react";
import styled from "styled-components";
import answer_footer from "./../../assets/images/answer_footer.png";
import black_pc from "./../../assets/images/black_pc.png";
import gift from "./../../assets/images/gift-01.png";
import Subtract from "./../../assets/images/Subtract.png";
import { useDispatch } from "react-redux";
import Button from "../../components/basics/button";
import { useNavigate, useLocation } from "react-router-dom";
import white_mobile from "./../../assets/images/white_mobile.png";
import white_pc from "./../../assets/images/white_pc.png";
import black_mobile from "./../../assets/images/black_mobile.png";
import { finishTranscript } from "../../action/api";
import { globalColor, text_sm_regular } from "./../../assets/variable/global";

export default function Thankyou(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isDesktop, setIsDesktop] = useState(true);
  const interview =
    location.state && location.state.interview
      ? location.state.interview
      : null;
  const slate =
    location.state && location.state.slate ? location.state.slate : null;
  const test =
    location.state && location.state.test ? location.state.test : null;
  const urlCode =
    location.state && location.state.url_code ? location.state.url_code : null;
  const logoURL =
    location.state && location.state.logo ? location.state.logo : "";
  const [thankyouEmail, setThankyouEmail] = useState("");
  const validateEmail = (email) => {
    if (email.length === 0) return true;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const finishTranscriptFunc = () => {
    if (!validateEmail(thankyouEmail)) {
      setInvalidEmail(true);
    } else {
      if (thankyouEmail !== "") {
        finishTranscript(
          "",
          interview.id,
          thankyouEmail,
          urlCode
        )(dispatch)
          .then((res) => {
            setEmailSubmitted(true);
          })
          .catch((err) => {});
      } else setEmailSubmitted(true);
    }
  };
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        finishTranscriptFunc();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return (
    <ThankyouWrapper>
      {test && (
        <NavBar>
          <h3 className="nav-item left">
            Test Drive | <span> {slate.name}</span>
          </h3>
          <div className="nav-item">
            <div className="toggle-button">
              <button
                className={`toggle-option ${isDesktop ? "active" : ""}`}
                onClick={() => setIsDesktop(true)}
              >
                <img src={isDesktop ? white_pc : black_pc} alt="pc" />
              </button>
              <button
                className={`toggle-option ${!isDesktop ? "active" : ""}`}
                onClick={() => setIsDesktop(false)}
              >
                <img src={!isDesktop ? white_mobile : black_mobile} alt="pc" />
              </button>
            </div>
          </div>
          <div className="nav-item right">
            <Button
              title="Close Test Drive "
              icon="closeIcon"
              onClickBtn={() => {
                if (slate)
                  navigate("/creator-dashboard/smart-interviews/" + slate.id, {
                    state: slate,
                  });
              }}
            />
          </div>
        </NavBar>
      )}
      <ThankyouHeader>
        {logoURL !== "" && <img src={logoURL} alt="Logo" />}
      </ThankyouHeader>
      <ThankyouBody>
        <h2>{interview.exit_message || "Thank you very much!"}</h2>
        {!test && !emailSubmitted && (
          <>
            {interview.incentive && interview.incentive.description && (
              <div className="coupon-incentive">
                <img src={gift} alt="gift" className="gift" />
                <div className="coupon-incentive-content">
                  <div style={text_sm_regular}>
                    {interview.incentive.description}
                  </div>
                </div>
              </div>
            )}
            {interview.incentive && interview.incentive.description && (
              <label className="label">
                Where should we email your reward?
              </label>
            )}
            <input
              type="text"
              className={`form-control ${invalidEmail && !validateEmail(thankyouEmail) && "form-validation"} ${!(interview.incentive && interview.incentive.description) && "no-incentive"}`}
              placeholder={
                interview.incentive && interview.incentive.description
                  ? "Please enter your email address"
                  : "Enter your email if you'd like to request a follow-up on your feedback"
              }
              value={thankyouEmail}
              onChange={(e) => {
                setThankyouEmail(e.target.value);
                setInvalidEmail(false);
              }}
            />
            {invalidEmail && !validateEmail(thankyouEmail) && (
              <p className="required-html">
                Oops! That’s not a valid email. Please try again before your
                inbox gets lonely!
              </p>
            )}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                title="Submit"
                full="regular"
                margin="auto"
                onClickBtn={() => {
                  finishTranscriptFunc();
                }}
              />
            </div>
          </>
        )}
        {!test && emailSubmitted && (
          <>
            {interview.incentive && interview.incentive.description && (
              <div style={{ display: "contents" }}>
                <div className="coupon-incentive">
                  <img src={gift} alt="gift" className="gift" />
                  <div className="coupon-incentive-content">
                    <div style={text_sm_regular}>
                      {interview.incentive.description}
                    </div>
                  </div>
                </div>
                <label className="label">
                  Please check your inbox for information about your token of
                  appreciation.
                </label>
                <p></p>
                <label>It should arrive by the next business day.</label>
              </div>
            )}
          </>
        )}
      </ThankyouBody>
      <div className="answerFooter">
        <img
          onClick={() => {
            window.open("https://www.perceptivepanda.com", "_blank");
          }}
          src={answer_footer}
          alt="footer"
        />
        <h4>Create a smart interview for your business!</h4>
      </div>
    </ThankyouWrapper>
  );
}

const ThankyouHeader = styled.div`
  background-color: #dfe4ed;
  display: flex;
  padding: 12px;
  img {
    height: 60px;
  }
  justify-content: center;
`;
const ThankyouBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px;
  flex-grow: 1;
  h2 {
    max-width: 600px;
    text-align: center;
    font-size: 30px;
    line-height: 38px;
    font-weight: bold;
    font-family: "Figtree";
    margin: 0px;
    padding-top: 40px;
  }
  img.coupon {
    padding: 40px 0px;
    max-width: 400px;
  }
  .label {
    padding-top: 20px;
  }
  input {
    width: 330px;
    &.no-incentive {
      width: 535px;
    }
    margin-top: 10px;
    margin-bottom: 10px;
    @media (max-width: 500px) {
      width: 90%;
    }
  }
  p.required-html {
    color: #d92d20;
    font-size: 14px;
    line-height: 24px;
  }
  .coupon-incentive {
    background-image: url(${Subtract});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 360px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin: 16px 0px;
    gap: 15px;
    img.gift {
      width: 48px;
      height: 48px;
    }
    .coupon-incentive-content {
      max-width: 245px;
      border-left: 2px solid rgba(102, 102, 102, 0.2);
      margin-left: 1px;
      border-left-style: dashed;
      padding-left: 10px;
    }
  }
`;
const ThankyouWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 500px) {
    height: 100vh;
  }
  background-color: #f7f9fb;
  .answerFooter {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 40px;
    img {
      padding-bottom: 8px;
      width: 228px;
      margin-left: auto;
      display: block;
      margin-right: auto;
      cursor: pointer;
    }
    h4 {
      color: ${globalColor.gray_500};
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      font-family: Figtree;
    }
  }
`;
const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${globalColor.gray_500};
  padding: 17px 32px;
  .nav-item {
    flex: 1;
    display: flex;
    justify-content: center;
    &.left {
      justify-content: start;
    }
    &.right {
      justify-content: end;
      align-items: center;
    }
  }
  h3 {
    color: ${globalColor.gray_600};
    margin: 0px;
    font-size: 30px;
    line-height: 38px;
    font-family: "Figtree";
    span {
      padding-left: 10px;
      font-weight: bold;
    }
  }
  .toggle-button {
    display: flex;
    border: 1px solid ${globalColor.gray_300};
    border-radius: 8px;
    overflow: hidden;
  }
  .toggle-option {
    flex: 1;
    padding: 12px 17px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  .toggle-option.active {
    background-color: ${globalColor.primary_600};
    color: #fff;
  }
  .toggle-option:not(.active):hover {
    background-color: #e0e0e0;
  }
`;
